import React, { ReactNode, Dispatch } from "react";
import { User, TreatmentRecord, Answer } from "./models";

interface IAction {
	type:
		| "SET_MESSAGE"
		| "SET_TREATMENT"
		| "SET_USER"
		| "DESTROY_STORE"
		| "SET_TREATMENT_EDIT_MODE"
		| "SET_QUESTIONNAIRE_PAGE"
		| "SET_ANSWER"
		| "SET_ANSWERS"
		| "SET_REVIEW_RECORD_ID"
		| "SET_TREATMENT_RECORD_ID"
		| "DESTROY_ANSWERS"
		| "SHOW_MODAL"
		| "CLOSE_MODAL";
	payload?: any;
}

interface IState {
	user?: User;
	patient: {
		currentTreatment: TreatmentRecord | null;
		editingTreatment: boolean;
	};
	questionnaire: {
		currentPage: number;
		answers: Answer[];
		reviewRecordId: string | null;
		treatmentRecordId: string | null;
	};
}

export const initialState: IState = {
	// user: un,
	patient: {
		currentTreatment: null,
		editingTreatment: false,
	},
	questionnaire: {
		currentPage: 0,
		answers: [],
		reviewRecordId: null,
		treatmentRecordId: null,
	},
};

function reducer(state: IState, action: IAction) {
	switch (action.type) {
		case "SET_QUESTIONNAIRE_PAGE":
			return {
				...state,
				questionnaire: {
					...state.questionnaire,
					currentPage: action.payload,
				},
			};

		case "SET_MESSAGE":
			return { ...state, message: action.payload };

		case "SET_TREATMENT":
			return {
				...state,
				patient: {
					...state.patient,
					currentTreatment: action.payload,
				},
			};

		case "SET_TREATMENT_EDIT_MODE":
			return {
				...state,
				patient: {
					...state.patient,
					editingTreatment: action.payload,
				},
			};

		case "SET_USER":
			return {
				...state,
				user: action.payload,
			};

		case "SET_REVIEW_RECORD_ID":
			return {
				...state,
				questionnaire: {
					...state.questionnaire,
					reviewRecordId: action.payload,
				},
			};

		case "SET_TREATMENT_RECORD_ID":
			return {
				...state,
				questionnaire: {
					...state.questionnaire,
					treatmentRecordId: action.payload,
				},
			};

		case "SET_ANSWER":
			const payload = action.payload;
			const questionType = payload.questionType;
			// Remove any duplicate answers first for all but checkboxes that can have multiple
			const existingAnswers =
				questionType !== "checkbox"
					? state.questionnaire.answers.filter(
							(answer: Answer) => answer.questionId !== payload.questionId
					  )
					: state.questionnaire.answers;

			delete payload.questionType; // Don't want to set questionType after we are done with it or it will be sent to server
			return {
				...state,
				questionnaire: {
					...state.questionnaire,
					answers: [...existingAnswers, payload],
				},
			};

		case "SET_ANSWERS":
			return {
				...state,
				questionnaire: {
					...state.questionnaire,
					answers: action.payload.answers,
				},
			};

		case "DESTROY_ANSWERS":
			return {
				...state,
				questionnaire: {
					...state.questionnaire,
					answers: [],
				},
			};

		case "DESTROY_STORE":
			return initialState;

		default:
			return state;
	}
}

export const StoreDispatch = React.createContext({} as Dispatch<IAction>);
export const StoreState = React.createContext(initialState);

interface StoreProviderProps {
	children: ReactNode;
}

export function StoreProvider(props: StoreProviderProps) {
	const [state, dispatch] = React.useReducer(reducer, initialState);
	return (
		<StoreDispatch.Provider value={dispatch}>
			<StoreState.Provider value={state}>{props.children}</StoreState.Provider>
		</StoreDispatch.Provider>
	);
}

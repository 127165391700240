import React, { useState, useEffect, useContext } from "react";
import { ReviewType } from "../../models";
import { get } from "../../services";
import Layout from "../../components/layouts/Main";
import { Email } from "../../models/Email";
import { StoreState } from "../../Store";
import ReviewItem from "../../components/reviews/ReviewItem";

const Reviews = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const [reviews, setReviews] = useState<null | [ReviewType]>(null);
	const [emails, setEmails] = useState<Email[]>([]);
	const [defaultEmail, setDefaultEmail] = useState<string>("");
	const [activePanel, setActivePanel] = useState<string>("");

	const state = useContext(StoreState);

	const handlePanelChange = (key: any) => {
		setActivePanel(key);
	};

	const getData = async () => {
		const reviewsRes = await get(`/review-types?clinicId=${state.user && state.user.clinicId}`);
		setReviews(reviewsRes.data);
		const emailsRes = await get("/mails/");
		setReviews(reviewsRes.data);
		setEmails(emailsRes.data);
		const defaultEmail = emailsRes.data.find((email: Email) => email.isDefault);
		setReviews(reviewsRes.data);
		defaultEmail && setDefaultEmail(defaultEmail.emailName);
		setLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<Layout title="Reviews" pagetitle="Reviews">
			{reviews &&
				reviews.map((globalReviewType: ReviewType) => (
					<ReviewItem
						key={globalReviewType.id}
						activeEmail={
							globalReviewType.emailToReviews.length > 0
								? globalReviewType.emailToReviews[0].email.emailName
								: null
						}
						emails={emails}
						globalReviewType={globalReviewType}
						activePanel={activePanel}
						handlePanelChange={handlePanelChange}
						defaultEmail={defaultEmail}
					/>
				))}
		</Layout>
	);
};

export default Reviews;

import React, { useState, ChangeEvent, FormEvent, Fragment, MouseEvent, useEffect } from "react";
import css from "./Notes.module.css";
import { Note, User, Patient } from "../../../models";
import {
	Comment,
	Tooltip,
	List,
	Avatar,
	Button,
	Icon,
	Form,
	message,
	Popconfirm,
	Modal,
	Card
} from "antd";
import moment from "moment";
import { stringToColour } from "../../../lib/stringToColor";
import TextArea from "antd/lib/input/TextArea";
import { post, destroy, put } from "../../../services";
import { FormComponentProps } from "antd/lib/form";

interface iNotes extends FormComponentProps {
	notes: Note[];
	user: User;
	patient: Patient;
	getNotes: () => void;
}

const Notes = ({ notes, user, patient, getNotes, form }: iNotes) => {
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [editing, setEditing] = useState<boolean>(false);
	const [currentNote, setCurrentNote] = useState<Note | null>(null);

	function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		form.validateFieldsAndScroll(async (err, values) => {
			if (!err) {
				try {
					setSubmitting(true);
					const res =
						editing && currentNote
							? await put("/notes/" + currentNote.id, {
									patientId: patient.id,
									content: values.editContent
							  })
							: await post("/notes", { patientId: patient.id, content: values.content });

					if (res.data) {
						message.success(`Note successfully ${editing ? "edited" : "created"}.`);
						setEditing(false);
						setCurrentNote(null);
						form.resetFields();
						getNotes();
					} else {
						message.error("Unexpected Server Error: Failed to create note.");
					}
				} catch {
					message.error("Unexpected Server Error: Failed to create note.");
				} finally {
					setSubmitting(false);
				}
			}
		});
	}

	async function delConfirm(noteId: string) {
		try {
			setSubmitting(true);
			const res = await destroy("/notes/" + noteId);
			if (res.data) {
				message.success("Note successfully deleted");
				getNotes();
			} else {
				message.error("Unexpected Server Error: Failed to delete note.");
			}
		} catch {
			message.error("Unexpected Server Error: Failed to delete note.");
		} finally {
			setSubmitting(false);
		}
	}

	const { getFieldDecorator } = form;

	return (
		<section className={css.notes}>
			<h2>
				<Icon type="file-text" style={{ color: "#757b86" }} /> Notes
			</h2>
			<Fragment>
				{!editing && (
					<Comment
						avatar={
							<Avatar style={{ backgroundColor: stringToColour(user.email) }}>
								{user.email.charAt(0).toUpperCase()}
							</Avatar>
						}
						content={
							<Form onSubmit={handleSubmit}>
								<Form.Item>
									{getFieldDecorator("content", {
										rules: [{ required: true, message: "Please enter note details" }]
									})(<TextArea rows={4} />)}
								</Form.Item>
								<Button
									style={{ marginLeft: editing ? "auto" : "initial", display: "block" }}
									htmlType="submit"
									loading={submitting}
									type="primary">
									Add note
								</Button>
							</Form>
						}
					/>
				)}
			</Fragment>

			<Modal
				destroyOnClose
				title="Edit Note"
				visible={editing}
				onCancel={() => setEditing(false)}
				afterClose={() => {
					setEditing(false);
					form.resetFields();
					getNotes();
				}}
				footer={null}>
				<Form onSubmit={handleSubmit}>
					<Form.Item>
						{getFieldDecorator("editContent", {
							rules: [{ required: editing, message: "Please enter note details" }],
							initialValue: currentNote && currentNote.content
						})(<TextArea rows={4} />)}
					</Form.Item>
					<Button
						style={{ marginLeft: editing ? "auto" : "initial", display: "block" }}
						htmlType="submit"
						loading={submitting}
						type="primary">
						Edit note
					</Button>
				</Form>
			</Modal>

			{notes.length ? (
				<List
					className="comment-list"
					header={`${notes.length} notes`}
					itemLayout="horizontal"
					dataSource={notes}
					renderItem={(note) => (
						<li>
							<Card size="small" style={{ margin: "10px 0", backgroundColor: "#fbfbfb" }}>
								<Comment
									actions={[
										<Button
											style={{ paddingLeft: 0, marginRight: 5 }}
											onClick={() => {
												setCurrentNote(note);
												setEditing(true);
											}}
											type="link"
											size="small">
											Edit
										</Button>,

										<Popconfirm
											title="Are you sure delete this note?"
											onConfirm={() => delConfirm(note.id)}
											okText="Yes"
											cancelText="No">
											<Button style={{ paddingLeft: 0, marginRight: 5 }} type="link" size="small">
												Delete
											</Button>
										</Popconfirm>
									]}
									author={note.createdBy}
									avatar={
										<Avatar style={{ backgroundColor: stringToColour(note.createdBy) }}>
											{note.createdBy.charAt(0).toUpperCase()}
										</Avatar>
									}
									content={
										<Fragment>
											{note.content}
											<NoteEditNotice note={note} show={note.createdAt !== note.updatedAt} />
										</Fragment>
									}
									datetime={
										<Tooltip title={moment(note.createdAt).format("DD/MM/YY HH:mm:ss")}>
											<span>{moment(note.createdAt).fromNow()}</span>
										</Tooltip>
									}
								/>
							</Card>
						</li>
					)}
				/>
			) : null}
		</section>
	);
};

interface NoteEditNoticeProps {
	note: Note;
	show: boolean;
}

const NoteEditNotice = ({ note, show }: NoteEditNoticeProps) => {
	if (!show) return null;

	return (
		<p className={css.noteEditNotice}>
			<span className={css.editorAuthor}>Note last edited by: {note.updatedBy}</span>{" "}
			<Tooltip title={moment(note.updatedAt).format("DD/MM/YY HH:mm:ss")}>
				<span>{moment(note.updatedAt).fromNow()}</span>
			</Tooltip>
		</p>
	);
};

export default Form.create<iNotes>()(Notes);

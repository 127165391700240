import React, { FormEvent, useEffect, useState } from "react";
import { FormComponentProps } from "antd/lib/form";
import { Form, Select, Skeleton, Input, Row, Col, Button, message } from "antd";
import { TreatmentType } from "../../models";
import { get, put, post } from "../../services";

interface Values {
	title: string;
	firstName: string;
	lastName: string;
	email: string;
	role: string;
	password: string;
}

interface Props extends FormComponentProps {
	treatmentTypeId: string | null;
	closeDrawer: () => void;
	getData: () => void;
	editing: boolean;
}

const CreateEditTreatmentTypeForm = ({ form, treatmentTypeId, closeDrawer, getData, editing }: Props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [treatmentType, setTreatmentType] = useState<TreatmentType | null>(null);

	const { getFieldDecorator } = form;

	async function getTreatmentType() {
		try {
			const res = await get("/treatment-types/" + treatmentTypeId);
			setTreatmentType(res.data);
		} catch (error) {
			console.log(error);
			message.error("Unexpected Server Error: Failed to load treatment type data.");
		}
	}

	useEffect(() => {
		if (treatmentTypeId) getTreatmentType();
	}, [treatmentTypeId]);

	function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		form.validateFieldsAndScroll(async (err, values: Values) => {
			if (!err) {
				setLoading(true);
				const res = editing ? await put("/treatment-types/" + treatmentTypeId, values) : await post("/treatment-types", values);
				if (res.data) {
					await getData();
					closeDrawer();
					message.success(`Successfully ${editing ? "updated" : "created"} treatment type`);
				} else {
					if (res.statusCode === 409) {
						message.error("A treatment type with this name address already exists.");
					} else {
						message.error("Unexpected Server Error: Failed to save treatment type data.");
					}
				}

				setLoading(false);
			}
		});
	}

	if ((editing && !treatmentTypeId) || (editing && !treatmentType)) {
		return <Skeleton active />;
	}

	return (
		<Form autoComplete="off" layout="vertical" onSubmit={(event) => handleSubmit(event)}>
			<Row gutter={16}>
				<Col sm={24}>
					<Form.Item label="Name">
						{getFieldDecorator("name", {
							...(editing && treatmentType && { initialValue: treatmentType.name }),
							rules: [
								{
									required: true,
									message: "Please enter a name"
								}
							]
						})(<Input />)}
					</Form.Item>
				</Col>
			</Row>

			<Form.Item>
				<Button
					loading={loading}
					disabled={loading}
					size="large"
					type="primary"
					htmlType="submit"
					block>
					Save
				</Button>
			</Form.Item>
		</Form>
	);
};

export default Form.create<Props>()(CreateEditTreatmentTypeForm);

import React from "react";
import Props from "./Icon.types";
import defaultProps from "./defaultProps";

const Clipboard = ({ size, fill, ...otherProps }: Props) => (
	<svg {...otherProps} width={size} height={size} fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="-77 0 512 512">
		<g fillRule="evenodd">
			<path d="M347.535 139.426c-5.523 0-10 4.476-10 10V492H20v-20.004c0-5.52-4.477-10-10-10s-10 4.48-10 10V502c0 5.523 4.477 10 10 10h337.535c5.524 0 10-4.477 10-10V149.426c0-5.524-4.476-10-10-10zm0 0M347.535 29.422h-83.578V10c0-5.523-4.477-10-10-10H103.578c-5.523 0-10 4.477-10 10v19.422H10c-5.523 0-10 4.476-10 10v352.574c0 5.524 4.477 10 10 10s10-4.476 10-10V49.422h73.578v19.422c0 5.523 4.477 10 10 10h150.379c5.523 0 10-4.477 10-10V49.422h73.578v20.004c0 5.52 4.477 10 10 10 5.524 0 10-4.48 10-10V39.422c0-5.524-4.476-10-10-10zM243.957 58.844H113.578V20h130.379zm0 0"/>
			<path d="M118.695 124.227H61.488c-5.523 0-10 4.476-10 10v57.21c0 5.524 4.477 10 10 10h57.207c5.524 0 10-4.476 10-10v-57.21c0-5.524-4.476-10-10-10zm-10 57.21H71.488v-37.21h37.207zm0 0M51.488 303.133c0 5.523 4.477 10 10 10h57.207c5.524 0 10-4.477 10-10v-57.207c0-5.524-4.476-10-10-10H61.488c-5.523 0-10 4.476-10 10zm20-47.207h37.207v37.207H71.488zm0 0M85.871 398.234L68.56 380.926c-3.907-3.906-10.239-3.906-14.141 0a9.996 9.996 0 0 0 0 14.14l24.383 24.383a9.961 9.961 0 0 0 7.07 2.93 9.968 9.968 0 0 0 7.07-2.93l48.766-48.761c3.902-3.907 3.902-10.239 0-14.145-3.906-3.906-10.238-3.902-14.144 0zm0 0M171.008 172.832H286.82c5.524 0 10-4.477 10-10s-4.476-10-10-10H171.008c-5.524 0-10 4.477-10 10s4.476 10 10 10zm0 0M171.008 291.336H286.82c5.524 0 10-4.477 10-10 0-5.524-4.476-10-10-10H171.008c-5.524 0-10 4.476-10 10 0 5.523 4.476 10 10 10zm0 0M171.008 409.84H286.82c5.524 0 10-4.477 10-10 0-5.524-4.476-10-10-10H171.008c-5.524 0-10 4.476-10 10 0 5.523 4.476 10 10 10zm0 0M10 441.996c5.508 0 10-4.488 10-10 0-5.508-4.492-10-10-10s-10 4.492-10 10c0 5.512 4.492 10 10 10zm0 0M347.535 99.426c-5.508 0-10 4.488-10 10 0 5.508 4.492 10 10 10s10-4.492 10-10c0-5.512-4.492-10-10-10zm0 0"/>
		</g>
	</svg>
);

Clipboard.defaultProps = defaultProps;

export default Clipboard;
import React from "react";
import Props from "./Icon.types";
import defaultProps from "./defaultProps";

const Cross = ({ size, fill, ...otherProps }: Props) => (
	<svg {...otherProps} viewBox="0 0 212.982 212.982" width={size} height={size} fill={fill}>
		<path fillRule="evenodd" d="M131.804 106.491l75.936-75.936c6.99-6.99 6.99-18.323 0-25.312-6.99-6.99-18.322-6.99-25.312 0L106.491 81.18 30.554 5.242c-6.99-6.99-18.322-6.99-25.312 0-6.989 6.99-6.989 18.323 0 25.312l75.937 75.936-75.937 75.937c-6.989 6.99-6.989 18.323 0 25.312 6.99 6.99 18.322 6.99 25.312 0l75.937-75.937 75.937 75.937c6.989 6.99 18.322 6.99 25.312 0 6.99-6.99 6.99-18.322 0-25.312l-75.936-75.936z" clipRule="evenodd"/>
	</svg>
);

Cross.defaultProps = defaultProps;

export default Cross;
import API from "../lib/axiosConfig";

import { Response } from "../models";

export default async function destroy(endpoint: string) {
	try {
		const response = await API.delete(endpoint);
		return response as Response;
	} catch (error) {
		return error as Response;
	}
}

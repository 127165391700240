import React, { FormEvent, useContext, useEffect, useState } from "react";
import { FormComponentProps } from "antd/lib/form";
import { Form, Select, Skeleton, Input, Row, Col, Button, message } from "antd";
import { Clinic, User, Location } from "../../models";
import { get, put, post } from "../../services";
import { StoreState } from "../../Store";

interface Values {
	title: string;
	firstName: string;
	lastName: string;
	email: string;
	role: string;
	password: string;
	locations: string[];
	clinicId: string;
}

interface Props extends FormComponentProps {
	currentUser: User | null;
	closeDrawer: () => void;
	getData: () => void;
	editing: boolean;
}

const CreateEditUserForm = ({ form, currentUser, closeDrawer, getData, editing }: Props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [user, setUser] = useState<User | null>(null);
	const [locations, setLocations] = useState<Location[]>([]);
	const [userLocations, setUserLocations] = useState<string[]>([]);
	const state = useContext(StoreState);
	const { user: contextUser } = state;

	const { getFieldDecorator } = form;
	const { Option } = Select;

	// async function getUser() {
	// 	try {
	// 		const res = await get("/users/" + `${currentUser && currentUser.id}`);
	// 		setUser(res.data);
	// 	} catch (error) {
	// 		console.log(error);
	// 		message.error("Unexpected Server Error: Failed to load user data.");
	// 	}
	// }

	async function getLocations() {
		try {
			const res = await get("/locations");
			console.log(res.data);
			if (res.data) {
				setLocations(res.data);
				if (currentUser) {
					if (editing && currentUser.locations.length > 0) {
						currentUser.locations.forEach((location: any) =>
							setUserLocations((prevState) => [...prevState, location.name])
						);
					}
				}
			} else {
				message.error("Unexpected Server Error: Failed to load locations");
			}
		} catch {
			message.error("Unexpected Server Error: Failed to load load locations");
		}
	}

	useEffect(() => {
		getLocations();
	}, [currentUser]);

	// useEffect(() => {
	// 	getClinics();
	// 	getLocations();

	// 	if (currentUser) getUser();
	// }, [currentUser]);

	function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		form.validateFieldsAndScroll(async (err, values: Values) => {
			if (!err) {
				setLoading(true);

				if (!values.locations) {
					values.locations = userLocations;
				}

				const locationIds = locations
					.filter((location: any) => values.locations.includes(location.name))
					.map((el) => el.id);

				const defaultLocationIds = locations.map((location) => location.id);

				if (values.locations.length === 0) {
					values.locations = defaultLocationIds;
				} else {
					values.locations = locationIds;
				}
				const res =
					editing && currentUser
						? await put("/users/" + currentUser.id, values)
						: await post("/users", values);
				if (res.data) {
					await getData();
					closeDrawer();
					message.success(`Successfully ${editing ? "updated" : "created"} user`);
				} else {
					if (res.statusCode === 409) {
						message.error("A user with this email address already exists.");
					} else {
						message.error("Unexpected Server Error: Failed to save user data.");
					}
				}

				setLoading(false);
			}
		});
	}

	if ((editing && !currentUser) || (editing && !currentUser)) {
		return <Skeleton active />;
	}

	return (
		<Form autoComplete="off" layout="vertical" onSubmit={(event) => handleSubmit(event)}>
			<Row gutter={16}>
				<Col sm={24}>
					<Form.Item label="Title">
						{getFieldDecorator("title", {
							...(editing && currentUser && { initialValue: currentUser.title }),
							rules: [
								{
									required: true,
									message: "Please select a title",
								},
							],
						})(
							<Select allowClear>
								<Option value="Mr">Mr</Option>
								<Option value="Mrs">Mrs</Option>
								<Option value="Miss">Miss</Option>
								<Option value="Ms">Ms</Option>
								<Option value="Dr">Dr</Option>
								<Option value="Master">Master</Option>
								<Option value="Professor">Professor</Option>
								<Option value="Mx">Mx</Option>
							</Select>
						)}
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={16}>
				<Col sm={12}>
					<Form.Item label="First name">
						{getFieldDecorator("firstName", {
							...(editing && currentUser && { initialValue: currentUser.firstName }),
							rules: [
								{
									required: true,
									message: "Please enter a first name",
								},
							],
						})(<Input />)}
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item label="Last name">
						{getFieldDecorator("lastName", {
							...(editing && currentUser && { initialValue: currentUser.lastName }),
							rules: [
								{
									required: true,
									message: "Please enter a last name",
								},
							],
						})(<Input />)}
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={16}>
				<Col sm={12}>
					<Form.Item label="Email">
						{getFieldDecorator("email", {
							...(editing && currentUser && { initialValue: currentUser.email }),
							rules: [
								{
									required: true,
									message: "Please enter an email address",
								},
							],
						})(<Input type="email" />)}
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item label="Role">
						{getFieldDecorator("role", {
							...(editing && currentUser && { initialValue: currentUser.role }),
							rules: [
								{
									required: true,
									message: "Please select a role",
								},
							],
						})(
							<Select allowClear>
								{contextUser && contextUser.role === "super-admin" && (
									<Option value="super-admin">Super-Admin</Option>
								)}
								<Option value="admin">Admin</Option>
								<Option value="analyst">Analyst</Option>
								<Option value="consultant">Consultant</Option>
							</Select>
						)}
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<Form.Item label="Password">
						{getFieldDecorator("password", {
							rules: [
								{
									required: editing ? false : true,
									message: "Please enter a password",
								},
							],
						})(<Input.Password autoComplete="new-password" />)}
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item label="Locations">
						{getFieldDecorator("locations", {
							...(currentUser && {
								initialValue: userLocations,
							}),

							trigger: "onBlur",
							valuePropName: "defaultValue",
							rules: [
								{
									required: true,
									message: "Please select at least one location",
								},
							],
						})(
							<Select
								id="locations"
								mode="multiple"
								allowClear
								showSearch
								optionFilterProp="children"
								value={userLocations}
								onChange={(e: any) => setUserLocations(e)}
								filterOption={(input: any, option: any) =>
									option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								placeholder="Select locations"
								//disabled={!locations.length && locations.length < 0}
								//loading={!locations.length && locations.length < 0 ? true : false}
							>
								<Option disabled value="">
									Select locations
								</Option>
								{locations.map((location: Location) => {
									return (
										<Option key={location.id} value={location.name}>
											{location.name}
										</Option>
									);
								})}
							</Select>
						)}
					</Form.Item>
				</Col>
			</Row>

			<Form.Item>
				<Button
					loading={loading}
					disabled={loading}
					size="large"
					type="primary"
					htmlType="submit"
					block>
					Save
				</Button>
			</Form.Item>
		</Form>
	);
};

export default Form.create<Props>()(CreateEditUserForm);

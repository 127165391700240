import React, { FormEvent, Fragment, useState } from "react";
import { Form, Select, message, Button, Input } from "antd";
import { post } from "../../../services";
import { FormComponentProps } from "antd/lib/form";
import { ReviewRecord, Patient } from "../../../models";

interface Props extends FormComponentProps {
	closeModal: () => void;
	patient: Patient;
	review: ReviewRecord;
}

interface Values {
	email: string;
	note: string;
}

const SendQuestionnaire = ({ patient, closeModal, review, form }: Props) => {
	const [loading, setLoading] = useState<boolean>(false);

	function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		form.validateFieldsAndScroll(async (err, values: Values) => {
			if (!err) {
				try {
					setLoading(true);
					const res = await post("/mails/patient-review-mail", {
						email: values.email,
						note: values.note,
						reviewRecordId: review.id,
					});

					if (res.data) {
						message.success("Email successfully sent");
						closeModal();
					} else {
						message.error("Unexpected Error: Failed to send questionnaire.");
					}
				} catch (error) {
					message.error("Unexpected Server Error: Failed to send questionnaires.");
				} finally {
					setLoading(false);
				}
			}
		});
	}

	const { getFieldDecorator } = form;
	const { Option } = Select;
	const { TextArea } = Input;

	const { otherEmails, email } = patient;

	return (
		<Form layout="vertical" onSubmit={(event) => handleSubmit(event)}>
			{/* If there are multiple emails on file for the patient then show a select to pick one */}
			{otherEmails ? (
				<Form.Item label="Select email address">
					{getFieldDecorator("email", {
						rules: [
							{
								required: true,
								message: "Please select a questionnaire",
							},
						],
					})(
						<Select placeholder="Select email address" allowClear>
							<Option disabled value="">
								Select email address
							</Option>

							<Option key={`primary${email}`} value={email}>
								Primary email: {email}
							</Option>

							<Option key={`secondary${email}`} value={otherEmails}>
								Secondary Emails: {otherEmails}
							</Option>
						</Select>
					)}
				</Form.Item>
			) : (
				<Fragment>
					<p>
						Sending <strong>{review.reviewType.name}</strong> questionnaires to the following email
						address: <strong>{email}</strong>
					</p>
					<br />
				</Fragment>
			)}

			<Form.Item label="Additional Notes (optional)">
				{getFieldDecorator("note")(
					<TextArea rows={6} placeholder="Add any additional information for the patient" />
				)}
			</Form.Item>

			<Form.Item style={{ marginBottom: 0, textAlign: "right" }}>
				<Button style={{ marginRight: 10 }} size="large" htmlType="button" onClick={closeModal}>
					Cancel
				</Button>

				<Button size="large" type="primary" htmlType="submit" loading={loading} disabled={loading}>
					Send Questionnaires
				</Button>
			</Form.Item>
		</Form>
	);
};

export default Form.create<Props>()(SendQuestionnaire);

import React, { useEffect, useState, Fragment, useContext } from "react";
import { RouteComponentProps } from "react-router";
import css from "./css/Patient.module.css";
import { get } from "../../services";
import { message, Skeleton, Icon } from "antd";
import Layout from "../../components/layouts/Main";
import Overview from "../../components/patients/Overview";
import Treatments from "../../components/patients/Treatments";
import Reviews from "../../components/patients/Reviews";
import Notes from "../../components/patients/Notes";
import { Patient as PatientProps, TreatmentRecord, Note } from "../../models";
import { StoreState, StoreDispatch } from "../../Store";
import { sortByDate } from "../../lib/sortByDate";

interface MatchParams {
	patientId: string;
}

const Patient = ({ match }: RouteComponentProps<MatchParams>) => {
	const dispatch = useContext(StoreDispatch);
	const [patient, setPatient] = useState<null | PatientProps>(null);
	const [treatments, setTreatments] = useState<null | TreatmentRecord[]>(null);
	const [notes, setNotes] = useState<null | Note[]>(null);

	const state = useContext(StoreState);
	const user = state.user;

	async function getNotes() {
		try {
			const res = await get(`/patients/${match.params.patientId}/notes`);
			setNotes(sortByDate(res.data, "createdAt", "DSC"));
			return res.data;
		} catch {
			message.error("Unexpected Server Error: Failed to load notes data.");
		}
	}

	async function getPatient() {
		try {
			const res = await get("/patients/" + match.params.patientId);
			setPatient(res.data);
			return res.data;
		} catch (error) {
			message.error("Unexpected Server Error: Failed to load patient data.");
		}
	}

	async function getTreatments() {
		try {
			const res = await get(
				`/patients/${match.params.patientId}/treatment-records?include=treatment,consultant,reviewRecords`
			);
			setTreatments(sortByDate(res.data, "startDate", "DSC"));
			return res.data;
		} catch {
			message.error("Unexpected Server Error: Failed to load treatment data.");
		}
	}

	useEffect(() => {
		getPatient();
		getTreatments();
		getNotes();
		return () => {
			dispatch({ type: "SET_TREATMENT", payload: null });
		};
	}, [match.params.patientId]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Layout
			title={
				<Fragment>
					<Icon type="user" style={{ color: "#757b86" }} /> Patient Overview
				</Fragment>
			}
			pagetitle={patient ? `${patient.title} ${patient.firstName} ${patient.lastName}` : null}>
			<div className={css.container}>
				<div className={css.panelLeft}>
					<div className={css.panelTopLeft}>
						{/* Overview */}
						{patient ? <Overview patient={patient} /> : <Skeleton active />}
					</div>

					{/* Treatments Table */}
					{treatments && patient ? (
						<Treatments
							treatments={treatments}
							initialTreatment={treatments[0]}
							getTreatments={getTreatments}
							patient={patient}
						/>
					) : (
						<Skeleton active />
					)}

					{/* Notes */}
					{notes && user && patient ? (
						<Notes notes={notes} user={user} patient={patient} getNotes={getNotes} />
					) : (
						<Skeleton active />
					)}
				</div>

				<div className={css.panelRight}>
					{/* Reviews */}
					{treatments && patient ? (
						<Reviews initialTreatment={treatments[0]} patient={patient} />
					) : (
						<Fragment>
							<Skeleton avatar paragraph={{ rows: 6 }} active />
							<Skeleton avatar paragraph={{ rows: 6 }} active />
							<Skeleton avatar paragraph={{ rows: 6 }} active />
							<Skeleton avatar paragraph={{ rows: 6 }} active />
						</Fragment>
					)}
				</div>
			</div>
		</Layout>
	);
};

export default Patient;

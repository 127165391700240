import API from "../lib/axiosConfig";

import { Response } from "../models";
export default async function patch(endpoint: string) {
	try {
		const response = await API.patch(endpoint);
		return response as Response;
	} catch (error) {
		return error as Response;
	}
}

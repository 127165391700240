import React, { FormEvent, useEffect, useState } from "react";
import { FormComponentProps } from "antd/lib/form";
import { Form, Select, Skeleton, Input, Row, Col, Button, message } from "antd";
import { BodyPart } from "../../models";
import { get, put, post } from "../../services";

interface Values {
	title: string;
	firstName: string;
	lastName: string;
	email: string;
	role: string;
	password: string;
}

interface Props extends FormComponentProps {
	bodyPartId: string | null;
	closeDrawer: () => void;
	getData: () => void;
	editing: boolean;
}

const CreateEditBodyPartForm = ({ form, bodyPartId, closeDrawer, getData, editing }: Props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [bodyPart, setBodyPart] = useState<BodyPart | null>(null);

	const { getFieldDecorator } = form;

	async function getBodyPart() {
		try {
			const res = await get("/body-parts/" + bodyPartId);
			setBodyPart(res.data);
		} catch (error) {
			console.log(error);
			message.error("Unexpected Server Error: Failed to load body part data.");
		}
	}

	useEffect(() => {
		if (bodyPartId) getBodyPart();
	}, [bodyPartId]);

	function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		form.validateFieldsAndScroll(async (err, values: Values) => {
			if (!err) {
				setLoading(true);
				const res = editing ? await put("/body-parts/" + bodyPartId, values) : await post("/body-parts", values);
				if (res.data) {
					await getData();
					closeDrawer();
					message.success(`Successfully ${editing ? "updated" : "created"} body part`);
				} else {
					if (res.statusCode === 409) {
						message.error("A body part with this name address already exists.");
					} else {
						message.error("Unexpected Server Error: Failed to save body part data.");
					}
				}

				setLoading(false);
			}
		});
	}

	if ((editing && !bodyPartId) || (editing && !bodyPart)) {
		return <Skeleton active />;
	}

	return (
		<Form autoComplete="off" layout="vertical" onSubmit={(event) => handleSubmit(event)}>
			<Row gutter={16}>
				<Col sm={24}>
					<Form.Item label="Name">
						{getFieldDecorator("name", {
							...(editing && bodyPart && { initialValue: bodyPart.name }),
							rules: [
								{
									required: true,
									message: "Please enter a name"
								}
							]
						})(<Input />)}
					</Form.Item>
				</Col>
			</Row>

			<Form.Item>
				<Button
					loading={loading}
					disabled={loading}
					size="large"
					type="primary"
					htmlType="submit"
					block>
					Save
				</Button>
			</Form.Item>
		</Form>
	);
};

export default Form.create<Props>()(CreateEditBodyPartForm);

import React, {
	useContext,
	useState,
	useEffect,
	ChangeEvent,
	useReducer,
	Dispatch,
	FormEvent
} from "react";
import { Alert, Button, Icon, Progress, Affix, message } from "antd";
import { Question as QuestionModel, Option, Patient, Questionnaire, Answer } from "../../../models";
import css from "./Form.module.css";
import { StoreState, StoreDispatch } from "../../../Store";
import { post } from "../../../services";
import useForm from "react-hook-form";
import scrollToElement from "scroll-to-element";

interface Props {
	maxPages: number;
	done: boolean;
	patient: Patient;
	questionnaire: Questionnaire;
	reviewRecordId: string;
	analystEdition?: boolean;
	closeAnalystModal?: () => void;
}

interface IComponentState {
	answers: Answer[];
	reviewRecordId: string | null;
}

const initialState: IComponentState = {
	answers: [],
	reviewRecordId: null
};

function reducer(
	state: IComponentState,
	action: { type: "SET_ANSWER" | "SET_ANSWERS"; payload: any }
) {
	switch (action.type) {
		case "SET_ANSWER":
			const payload = action.payload;
			const questionType = payload.questionType;
			// Remove any duplicate answers first for all but checkboxes that can have multiple
			const existingAnswers =
				questionType !== "checkbox"
					? state.answers.filter((answer: Answer) => answer.questionId !== payload.questionId)
					: state.answers;

			delete payload.questionType; // Don't want to set questionType after we are done with it or it will be sent to server
			return {
				...state,
				answers: [...existingAnswers, payload]
			};

		default:
			return state;
	}
}

const ComponentDispatch = React.createContext({} as Dispatch<any>);
const ComponentState = React.createContext(initialState);

const QuestionnaireForm = ({
	questionnaire,
	maxPages,
	done,
	patient,
	reviewRecordId,
	analystEdition = false,
	closeAnalystModal
}: Props) => {
	const state = React.useContext(StoreState);
	const dispatch = React.useContext(StoreDispatch);

	const [progressBarStuck, setProgressBarStuck] = useState<boolean | undefined>(true);
	const [numAnsweredQuestions, setNumAnsweredQuestions] = useState<number>(0);

	const [componentState, componentDispatch] = useReducer(reducer, {
		reviewRecordId: reviewRecordId,
		answers: questionnaire.answers
	});

	const { currentPage } = state.questionnaire;

	const questions = questionnaire.questions.sort((q1, q2) => q1.order - q2.order);

	function next() {
		window.scrollTo(0, 0);
		dispatch({
			type: "SET_QUESTIONNAIRE_PAGE",
			payload: currentPage + 1
		});

		dispatch({ type: "DESTROY_ANSWERS" });
	}

	function prev() {
		window.scrollTo(0, 0);
		dispatch({
			type: "SET_QUESTIONNAIRE_PAGE",
			payload: currentPage - 1
		});
	}

	// Set initial values if they exist
	let initialValues: any = {};
	questionnaire.answers.forEach((answer: Answer) => {
		return (initialValues[answer.questionId] = answer.optionId || answer.score || answer.text);
	});

	const form = useForm({
		mode: "onBlur",
		defaultValues: initialValues
	});

	const { register, handleSubmit, errors, formState, setValue } = form;

	useEffect(() => {
		if (errors) {
			// Scroll to first error
			const errorKeys = Object.keys(errors);
			const firstError: HTMLElement | null = document.getElementById(errorKeys[0]);
			if (firstError) {
				scrollToElement(firstError, {
					offset: -200,
					duration: 500
				});
			}
		}
	}, [formState.isSubmitting]);

	useEffect(() => {
		const answeredQuestions = questions.filter(
			(question: QuestionModel) =>
				question.required &&
				componentState.answers.some((answer: Answer) => answer.questionId === question.id)
		);

		setNumAnsweredQuestions(answeredQuestions.length);
	}, [formState]);

	const onSubmit = handleSubmit(async (values: any) => {
		const res = await post("/answers/bulk", componentState.answers);

		if (res.data) {
			// Reset progress bar & continue to next page
			!analystEdition && next();

			if (analystEdition && closeAnalystModal) {
				closeAnalystModal();
				message.success("Questionnaire successfully updated");
			}
		} else {
			if (navigator.onLine) {
				message.error("Unexpected server error: Failed to save your answers, please try again.");
			} else {
				message.error(
					"Error: Failed to save your answers, please check your internet connection and try again try again."
				);
			}
		}
	});

	const percent =
		(100 * numAnsweredQuestions) /
		questions.filter((question: QuestionModel) => question.required).length;
	const roundedPercent = Math.round(percent * 1) / 1;

	return (
		<ComponentDispatch.Provider value={componentDispatch}>
			<ComponentState.Provider value={componentState}>
				<form onSubmit={onSubmit}>
					{questions.map((question: QuestionModel, index: number) => (
						<Question
							question={question}
							index={index}
							key={question.id}
							register={register}
							questionnaire={questionnaire}
							errors={errors}
							patient={patient}
							form={form}
						/>
					))}

					<div className={css.buttons}>
						{!analystEdition && currentPage > 0 && (
							<Button size="large" style={{ marginLeft: 8 }} onClick={prev} icon="left">
								Previous
							</Button>
						)}

						{!done && (
							<Button
								size="large"
								type="primary"
								htmlType="submit"
								style={!analystEdition ? { marginLeft: "auto" } : undefined}
								loading={formState.isSubmitting}
								disabled={formState.isSubmitting}>
								{analystEdition ? "Save" : currentPage !== maxPages - 1 ? "Next" : "Finish"}
								<Icon type={currentPage !== maxPages - 1 && !analystEdition ? "right" : "check"} />
							</Button>
						)}
					</div>

					{!analystEdition && numAnsweredQuestions > 0 && (
						<Affix offsetBottom={0} onChange={(affixed) => setProgressBarStuck(affixed)}>
							<Progress
								className={[css.progressBar, progressBarStuck ? "" : css.stuck].join(" ")}
								percent={roundedPercent}
							/>
						</Affix>
					)}
				</form>
			</ComponentState.Provider>
		</ComponentDispatch.Provider>
	);
};

interface QuestionProps {
	question: QuestionModel;
	index: number;
	register: any;
	questionnaire: Questionnaire;
	errors: any;
	patient: Patient;
	form: any;
}

const Question = ({
	question,
	index,
	register,
	questionnaire,
	errors,
	patient,
	form
}: QuestionProps) => {
	const { title, subtitle, description, type, options } = question;

	return (
		<div className={css.question}>
			<h3 className={css.questionTitle} data-count={index + 1}>
				{title}
			</h3>
			{subtitle && <h4 className={css.questionSubTitle}>{subtitle}</h4>}

			<div className={css.description} dangerouslySetInnerHTML={{ __html: description }} />

			<RenderQuestion
				questionnaire={questionnaire}
				question={question}
				type={type}
				options={options.sort((a: Option, b: Option) => a.order - b.order)}
				register={register}
				errors={errors}
				patient={patient}
				form={form}
			/>
		</div>
	);
};

interface RenderQuestionProps {
	type: string;
	options: Option[];
	question: QuestionModel;
	questionnaire: Questionnaire;
	register: any;
	errors: any;
	patient: Patient;
	form: any;
}

const RenderQuestion = ({
	type,
	options,
	question,
	questionnaire,
	register,
	errors,
	patient,
	form
}: RenderQuestionProps) => {
	const { setValue, getValues } = form;

	function generateScores(option: Option) {
		return {
			score: question.scoring ? option.score : null,
			scoreADL: option.scoreADL,
			scoreMental: option.scoreMental,
			scorePain: option.scorePain,
			scorePhysical: option.scorePhysical,
			scoreQOL: option.scoreQOL,
			scoreSports: option.scoreSports,
			scoreSymptoms: option.scoreSymptoms,
			scoreWOMACSADL: option.scoreWOMACSADL,
			scoreWOMACSPain: option.scoreWOMACSPain,
			scoreWOMACSStiffness: option.scoreWOMACSStiffness,
			scoreWOMACSTotal: option.scoreWOMACSTotal,
			scoreWork: option.scoreWork
		};
	}

	const existingAnswer = questionnaire.answers.find(
		(answer: Answer) => answer.questionId === question.id
	);

	const componentDispatch = useContext(ComponentDispatch);
	const componentState = useContext(ComponentState);
	const reviewRecordId = existingAnswer
		? existingAnswer.reviewRecordId
		: componentState.reviewRecordId;

	const constants = {
		patientId: patient.id,
		questionnaireId: questionnaire.id,
		reviewRecordId: reviewRecordId,
		...(existingAnswer && question.type !== "checkbox" ? { id: existingAnswer.id } : {})
	};

	const Error = () => {
		if (errors[question.id]) {
			return (
				<Alert style={{ marginTop: 15 }} message="This is a required field" type="error" showIcon />
			);
		}

		return null;
	};

	switch (type) {
		case "radio":
			return (
				<div id={question.id} className="questionWrapper">
					<div className={[css.radioRow, question.inline ? css.inlineRadio : ""].join(" ")}>
						{options.map((option) => (
							<div className={css.radioWrapper} key={option.id}>
								<input
									ref={register({ required: question.required })}
									type="radio"
									id={option.id}
									className={css.radio}
									name={question.id}
									value={option.id}
									onChange={() => {
										const scores = generateScores(option);
										componentDispatch({
											type: "SET_ANSWER",
											payload: {
												...constants,
												questionId: question.id,
												optionId: option.id,
												...scores
											}
										});
									}}
								/>
								<label className={css.radioLabel} htmlFor={option.id}>
									<span style={{ flex: "1" }}>{option.title}</span>
								</label>
							</div>
						))}
					</div>
					<Error />
				</div>
			);

		case "text":
			return (
				<div id={question.id} className="questionWrapper">
					<input
						className={css.input}
						type="text"
						name={question.id}
						ref={register({ required: question.required })}
						onBlur={(event) => {
							componentDispatch({
								type: "SET_ANSWER",
								payload: {
									...constants,
									questionId: question.id,
									text: event.currentTarget.value
								}
							});
						}}
					/>
					<Error />
				</div>
			);

		case "textarea":
			return (
				<div id={question.id} className="questionWrapper">
					<textarea
						rows={4}
						style={{ height: "auto" }}
						className={css.input}
						name={question.id}
						ref={register({ required: question.required })}
						onBlur={(event) => {
							componentDispatch({
								type: "SET_ANSWER",
								payload: {
									...constants,
									questionId: question.id,
									text: event.currentTarget.value
								}
							});
						}}
					/>
					<Error />
				</div>
			);

		case "checkbox":
			return (
				<div id={question.id} className="questionWrapper">
					<div className={[css.checkboxRow, question.inline ? css.inlineCheckbox : ""].join(" ")}>
						{options.map((option) => {
							return (
								<div className={css.checkboxWrapper} key={option.id}>
									<input
										ref={register({ name: question.id, required: question.required })}
										type="checkbox"
										defaultChecked={questionnaire.answers.some(
											(answer: Answer) => answer.optionId === option.id
										)}
										className={css.checkbox}
										id={option.id}
										name={question.id}
										value={option.id}
										onChange={(event) => {
											const scores = generateScores(option);
											componentDispatch({
												type: "SET_ANSWER",
												payload: {
													questionType: "checkbox",
													...constants,
													questionId: question.id,
													optionId: option.id,
													delete: event.currentTarget.checked ? false : true,
													...scores
												}
											});
										}}
									/>
									<label className={css.checkboxLabel} htmlFor={option.id}>
										{option.title}
									</label>
								</div>
							);
						})}
					</div>
					<Error />
				</div>
			);

		case "range":
			const setAnswer = (event: FormEvent<HTMLInputElement>) => {
				componentDispatch({
					type: "SET_ANSWER",
					payload: {
						...constants,
						questionId: question.id,
						score: question.scoring ? Number(event.currentTarget.value) : null,
						text: question.scoring ? null : event.currentTarget.value
					}
				});
			};

			const currentValue = getValues()[question.id] || question.min;
			const progressPercent = currentValue
				? ((Number(currentValue) - question.min) * 100) / (question.max - question.min)
				: 0;

			return (
				<div id={question.id} className="questionWrapper">
					<div className="rangeSlider">
						<div className={css.rangeContainer}>
							<div className={css.rangeLabels}>
								<span>
									<strong>{question.min}</strong>
								</span>
								<span>
									<strong>{question.max}</strong>
								</span>
							</div>
							<div className={css.barWrap}>
								<input
									type="range"
									min={question.min}
									max={question.max}
									step={question.increment}
									className={css.rangeInput}
									value={currentValue}
									onChange={(event) => {
										setAnswer(event);
										setValue([question.id], event.currentTarget.value);
									}}
								/>

								{/* Circle */}
								<div
									style={{
										left: `${progressPercent}%`
									}}
									className={css.rangeCircle}>
									<span className={css.circleTooltip}>{currentValue}</span>
								</div>

								{/* Progress bar */}
								<div
									style={{
										width: `${progressPercent}%`
									}}
									className={css.rangeProgress}
								/>
							</div>

							{/* Labels */}
							<div className={css.rangeLabels}>
								<span>{question.minLabel}</span>
								<span>{question.maxLabel}</span>
							</div>
						</div>

						<input
							ref={register({ required: question.required })}
							name={question.id}
							type="number"
							min={question.min}
							max={question.max}
							step={question.increment}
							className={css.input}
							style={{ display: "inline-block", width: "auto" }}
							onChange={setAnswer}
						/>
					</div>

					<Error />
				</div>
			);

		default:
			return (
				<Alert
					message="Error loading question"
					description="If this problem persists please contact support."
					type="error"
					showIcon
				/>
			);
	}
};

export default QuestionnaireForm;

import React, { useState, useEffect, Fragment, useContext } from "react";
import Layout from "../../components/layouts/PatientForm";
import { Skeleton, Affix, Result, Divider } from "antd";
import { TreatmentRecord, Patient, Questionnaire } from "../../models";
import { RouteComponentProps } from "react-router";
import { get } from "../../services";
import RegenLogo from "../../static/images/RegenLogo.png";
import css from "./PatientForm.module.css";
import Cookies from "js-cookie";
import { getUrlParameter } from "../../lib";
import Form from "../../components/patient-form/Form";
import { StoreState, StoreDispatch } from "../../Store";

const PatientForm = ({ location }: RouteComponentProps) => {
	const [treatmentRecord, setTreatmentRecord] = useState<TreatmentRecord | null>(null);
	const [patient, setPatient] = useState<Patient | null>(null);
	const [questionnaire, setQuestionnaire] = useState<Questionnaire | null>(null);
	const [unauthorized, setUnauthorized] = useState<boolean>(false);
	const [showQuestionnaireTitle, setShowQuestionnaireTitle] = useState<boolean | undefined>(
		undefined
	);

	const dispatch = useContext(StoreDispatch);
	const state = useContext(StoreState);

	const { currentPage } = state.questionnaire;

	// URL Params
	const token = getUrlParameter("token", location.search);
	const analyst = getUrlParameter("analyst", location.search);
	const treatmentRecordId = getUrlParameter("treatmentRecordId", location.search);
	const reviewRecordId = getUrlParameter("reviewRecordId", location.search);
	const questionnaires = getUrlParameter("questionnaires", location.search);
	const questionnairesArray = questionnaires.split(",");

	useEffect(() => {
		dispatch({
			type: "SET_REVIEW_RECORD_ID",
			payload: reviewRecordId,
		});

		dispatch({
			type: "SET_TREATMENT_RECORD_ID",
			payload: treatmentRecordId,
		});
	}, []);

	async function getTreatment() {
		try {
			const res = await get("/treatment-records/" + treatmentRecordId + "?include=treatment");
			setTreatmentRecord(res.data);
		} catch (error) {
			// Handle error
			if (error.statusCode === 401) {
				setUnauthorized(true);
			}
		}
	}

	async function getPatient() {
		try {
			// Set Patient
			// Set token so Axios interceptor includes it in the auth header
			Cookies.set("patientToken", token as string);
			const res = await get("/patients/me?include=clinic");
			setPatient(res.data);
		} catch (error) {
			// Handle error
			if (error.statusCode === 401) {
				setUnauthorized(true);
			}
		}
	}

	async function getQuestionnaires() {
		try {
			const res = await get(
				`/review-records/${reviewRecordId}/questionnaires/${questionnairesArray[currentPage]}`
			);
			setQuestionnaire(res.data);
		} catch (error) {
			// Handle error
			if (error.statusCode === 401) {
				setUnauthorized(true);
			}
		}
	}

	useEffect(() => {
		setQuestionnaire(null);
		if (!treatmentRecord) getTreatment();
		if (!patient) getPatient();
		if (!done) getQuestionnaires();
	}, [currentPage]);

	const maxPages = questionnairesArray.length;
	const done = currentPage === maxPages;

	if (unauthorized) {
		return (
			<Layout>
				<Result
					status="warning"
					title="Your questionnaire request has expired"
					subTitle={
						<p>
							Please contact your assigned analyst{" "}
							{analyst && <a href={"mailto:" + analyst}>{analyst}</a>} to request a new set of
							questionnaires.
						</p>
					}
				/>
			</Layout>
		);
	}

	const ProgressBar = () => {
		if (!done) {
			return (
				<div className={[css.progress, showQuestionnaireTitle ? css.fixed : ""].join(" ")}>
					{showQuestionnaireTitle && questionnaire && treatmentRecord ? (
						<span>
							{treatmentRecord.treatment.treatmentType.name}{" "}
							{treatmentRecord.treatment.bodyPart.name}{" "}
							{treatmentRecord.side && treatmentRecord.side !== "NR"
								? `(${treatmentRecord.side})`
								: ""}
							: {questionnaire.name}{" "}
							<span style={{ fontWeight: "normal" }}>
								(Questionnaire {currentPage + 1} of {maxPages})
							</span>
						</span>
					) : (
						`Questionnaire ${currentPage + 1} of ${maxPages}`
					)}
				</div>
			);
		}
		return null;
	};

	return (
		<Layout>
			<Affix className="progress-affix" onChange={(affixed) => setShowQuestionnaireTitle(affixed)}>
				<ProgressBar />
			</Affix>

			{treatmentRecord && patient ? (
				<Header
					patient={patient}
					treatmentRecord={treatmentRecord}
					done={done}
					questionnaire={questionnaire || null}
				/>
			) : (
				<Skeleton active avatar />
			)}

			<section className="questions">
				{patient && questionnaire && questionnaire.questions ? (
					<Form
						questionnaire={questionnaire}
						maxPages={maxPages}
						done={done}
						patient={patient}
						reviewRecordId={reviewRecordId}
					/>
				) : (
					!done && <Skeleton avatar active />
				)}
			</section>
		</Layout>
	);
};

interface HeaderProps {
	treatmentRecord: TreatmentRecord;
	patient: Patient;
	done: boolean;
	questionnaire: Questionnaire | null;
}

const Header = ({ treatmentRecord, patient, done, questionnaire }: HeaderProps) => {
	const side = treatmentRecord.side;
	const bodyPart = treatmentRecord.treatment.bodyPart.name;
	const study = treatmentRecord.treatment.treatmentType.name;
	const clinic = patient.clinic;

	return (
		<section className={css.header}>
			<div className={css.topbar}>
				{clinic && clinic.logo && (
					<img
						style={{ objectFit: "contain" }}
						src={clinic.logo}
						className={css.logo}
						alt={clinic.name}
					/>
				)}

				<div className={css.patientDetails}>
					<p>
						{patient.title} {patient.aka ? patient.aka : patient.firstName} {patient.lastName}
					</p>
					<p>{patient.email}</p>
				</div>
			</div>

			{!done && (
				<h1 className={css.procedureTitle}>
					{study} {bodyPart} {side && side !== "NR" ? `(${side})` : ""}
				</h1>
			)}

			{!done && <Divider />}

			{done && (
				<Result
					status="success"
					title="Thank you, we have collected your answers"
					subTitle="You may now close this window."
				/>
			)}

			{questionnaire && !done ? (
				<Fragment>
					<h2>{questionnaire.name}</h2>
					<div
						className={css.description}
						dangerouslySetInnerHTML={{ __html: questionnaire.description }}
					/>
				</Fragment>
			) : (
				!done && <Skeleton avatar active />
			)}
		</section>
	);
};

export default PatientForm;

import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Main";
import CreateEditEmailForm from "../../components/emails/CreateEditEmailForm";
import { RouteComponentProps } from "react-router";
import { Email } from "../../models/Email";
import { message, Skeleton } from "antd";
import { get } from "../../services";

interface Match {
	emailId: string;
}

const CreateEditEmail = ({ match }: RouteComponentProps<Match>) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [email, setEmail] = useState<null | Email>(null);

	async function getEmail() {
		try {
			const res = await get("/mails/" + match.params.emailId);
			setEmail(res.data);
			if (res.data) {
				setLoading(false);
			} else {
				message.error("Unexpected Server Error: Failed to load email data 1).");
			}
		} catch (error) {
			console.log(error);
			message.error("Unexpected Server Error: Failed to load email data 2).");
		}
	}

	useEffect(() => {
		if (match.params.emailId) {
			getEmail();
		} else {
			setLoading(false);
		}
	}, [match.params.emailId]);

	if (loading) {
		return (
			<Layout>
				<Skeleton paragraph={{ rows: 20 }} active />
			</Layout>
		);
	}

	const title = `${email ? "Edit" : "New"} Email`;

	return (
		<Layout title={title} pagetitle={title}>
			<CreateEditEmailForm email={email || null} />
		</Layout>
	);
};

export default CreateEditEmail;

import React, { useState, useEffect, Fragment } from "react";
import { destroy, get } from "../../services";
import Layout from "../../components/layouts/Main";
import { Clinic } from "../../models";
import { Button, Table, Tag, Icon, message, Drawer, Tooltip, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { stringToColour } from "../../lib/stringToColor";
import EditClinicForm from "../../components/clinics/CreateEditClinicForm";

const Clinics = () => {
	const [clinics, setClinics] = useState<Clinic[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [showCreateEdit, setShowCreateEdit] = useState<boolean>(false);
	const [currentClinic, setCurrentClinic] = useState<string | null>(null);
	const [editing, setEditing] = useState<boolean>(false);

	const getData = async () => {
		const res = await get("/clinics");
		try {
			setClinics(res.data);
		} catch (error) {
			message.error("Unexpected Server Error: Failed to load clinci data.");
		}

		setLoading(false);
	};

	async function deleteClinic(clinicId: string) {
		try {
			const res = await destroy("/clinics/" + clinicId);
			if (res.status === 200) {
				const updatedClinics: Clinic[] =
					clinics && clinics.filter((clinic: Clinic) => clinic.id !== clinicId);
				setClinics(updatedClinics);
				message.success("Clinic successfully deleted");
				return;
			}
			if (res.status === 409) {
				message.error(res.message);
				return;
			} else {
				message.error("Error while attempting to delete clinic.");
			}
		} catch (error) {
			message.error("Unexpected Server Error: Failed to delete clinic.");
		}
	}
	function onEdit(userId: string) {
		setEditing(true);
		setCurrentClinic(userId);
		setShowCreateEdit(true);
	}

	function onNew() {
		setEditing(false);
		setShowCreateEdit(true);
	}

	useEffect(() => {
		getData();
	}, []);

	const columns = [
		{
			title: "Name",
			key: "clinic.name",
			sorter: (a: Clinic, b: Clinic) => a.name.localeCompare(b.name),
			render: (text: string, row: Clinic) => <Fragment>{row.name}</Fragment>,
		},
		{
			title: "Logo",
			key: "clinic.logo",
			render: (text: string, row: Clinic) => (
				<Fragment>
					{row.logo ? (
						<img
							src={row.logo}
							alt="Logo"
							style={{ maxWidth: "12rem", maxHeight: "12rem", borderRadius: "1rem" }}
						/>
					) : (
						<div style={{ fontSize: "2rem" }}>
							<span>-</span>
						</div>
					)}
				</Fragment>
			),
		},
		{
			title: "Email",
			key: "clinic.email",
			render: (text: string, row: Clinic) => <Fragment>{row.email}</Fragment>,
			sorter: (a: Clinic, b: Clinic) => {
				const emailA = a.email || "";
				const emailB = b.email || "";
				return emailA.localeCompare(emailB);
			},
		},
		{
			title: "Contact Number",
			key: "clinic.contactNumber",
			render: (text: string, row: Clinic) => (
				<Fragment>{row.contactNumber && row.contactNumber}</Fragment>
			),
			sorter: (a: Clinic, b: Clinic) => {
				const contactNumberA = a.contactNumber || "";
				const contactNumberB = b.contactNumber || "";
				return contactNumberA.localeCompare(contactNumberB);
			},
		},
		{
			title: "Address",
			key: "clinic.address",
			render: (text: string, row: Clinic) => <Fragment>{row.address && row.address}</Fragment>,
			sorter: (a: Clinic, b: Clinic) => {
				const addressA = a.address || "";
				const addressB = b.address || "";
				return addressA.localeCompare(addressB);
			},
		},
		{
			title: "Power BI link",
			key: "clinic.powerBiLink",
			render: (text: string, row: Clinic) => (
				<div>
					{row.power_bi_link && row.power_bi_link.length > 20 ? (
						<Tooltip title={`Full link - ${row.power_bi_link}`}>
							{row.power_bi_link.slice(0, 20) + "..."}
						</Tooltip>
					) : (
						row.power_bi_link && row.power_bi_link
					)}
				</div>
			),
			sorter: (a: Clinic, b: Clinic) => {
				const pbLinkA = a.power_bi_link || "";
				const pbLinkB = b.power_bi_link || "";
				return pbLinkA.localeCompare(pbLinkB);
			},
		},
		{
			title: "",
			align: "right" as "right",
			key: "id",
			render: (text: string, row: Clinic) => (
				<Fragment>
					<Button size="default" type="link" onClick={() => onEdit(row.id)}>
						Edit
						<Icon type="edit" />
					</Button>
					<Popconfirm
						title="Are you sure delete this clinic?"
						onConfirm={() => deleteClinic(row.id)}
						okText="Yes"
						cancelText="No">
						<Button size="default" type="link" style={{ color: "#a20909" }}>
							Delete
							<Icon type="delete" />
						</Button>
					</Popconfirm>
				</Fragment>
			),
		},
	];

	return (
		<Layout title="Clinics" pagetitle="Clinics">
			<Button type="primary" size="default" icon="plus" onClick={onNew}>
				Add new Clinic
			</Button>

			<Table
				loading={loading}
				rowKey="id"
				style={{ marginTop: 25 }}
				dataSource={clinics.sort((a: Clinic, b: Clinic) => a.name.localeCompare(b.name))}
				columns={columns}
				pagination={{}}
			/>

			<Drawer
				width={600}
				title={`${editing ? "Edit" : "Create new"} clinic`}
				placement="right"
				closable={true}
				destroyOnClose
				onClose={() => setShowCreateEdit(false)}
				visible={showCreateEdit}>
				<EditClinicForm
					editing={editing}
					clinicId={currentClinic || null}
					closeDrawer={() => setShowCreateEdit(false)}
					getData={getData}
				/>
			</Drawer>
		</Layout>
	);
};

export default Clinics;

import React from "react";

import Layout from "../../components/layouts/Main";
import { Link } from "react-router-dom";
import { Button } from "antd";

const Error404 = () => {
	return (
		<Layout title="404 page not found" pagetitle="Page not found">
			<p>The page you were looking for could not be found.</p>
			<Link to="/">
				<Button>Return Home</Button>
			</Link>
		</Layout>
	);
};

export default Error404;

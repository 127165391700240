import { useContext } from "react";
import { StoreState } from "../../Store";

export interface MenuItemInterface {
	key: string;
	link: string;
	icon: string;
	label: string;
}

export default function useMenuItems(): MenuItemInterface[] {
	const state = useContext(StoreState);
	const { user } = state;
	let role: string = "";
	if (user) {
		role = user.role;
	}
	const superAdminMenuItems: MenuItemInterface[] = [
		{ key: "/", link: "/", icon: "home", label: "Dashboard" },
		{ key: "/patients", link: "/patients", icon: "team", label: "Patients" },
		{ key: "/users", link: "/users", icon: "user", label: "Users" },
		{ key: "/clinics", link: "/clinics", icon: "medicine-box", label: "Clinics" },
		{ key: "/locations", link: "/locations", icon: "environment", label: "Locations" },
		{
			key: "/questionnaires",
			link: "/questionnaires",
			icon: "container",
			label: "Questionnaires",
		},
		{ key: "/reviews", link: "/reviews", icon: "solution", label: "Reviews" },
		{ key: "/treatments", link: "/treatments", icon: "medicine-box", label: "Treatments" },
		{
			key: "/treatments/types",
			link: "/treatments/types",
			icon: "deployment-unit",
			label: "Treatment Types",
		},
		{
			key: "/treatments/body-parts",
			link: "/treatments/body-parts",
			icon: "skin",
			label: "Body Parts",
		},
		{
			key: "/emails",
			link: "/emails",
			icon: "mail",
			label: "Email",
		},
	];

	const adminMenuItems: MenuItemInterface[] = [
		{ key: "/", link: "/", icon: "home", label: "Dashboard" },
		{ key: "/patients", link: "/patients", icon: "team", label: "Patients" },
		{ key: "/users", link: "/users", icon: "user", label: "Users" },
		{ key: "/locations", link: "/locations", icon: "environment", label: "Locations" },

		{
			key: "/questionnaires",
			link: "/questionnaires",
			icon: "container",
			label: "Questionnaires",
		},
		{ key: "/reviews", link: "/reviews", icon: "solution", label: "Reviews" },
		{
			key: "/emails",
			link: "/emails",
			icon: "mail",
			label: "Email",
		},
	];

	const consultantMenuItems: MenuItemInterface[] = [
		{ key: "/", link: "/", icon: "home", label: "Dashboard" },
		{ key: "/patients", link: "/patients", icon: "team", label: "Patients" },
		{
			key: "/questionnaires",
			link: "/questionnaires",
			icon: "container",
			label: "Questionnaires",
		},
		{ key: "/treatments", link: "/treatments", icon: "medicine-box", label: "Treatments" },
		{
			key: "/treatments/types",
			link: "/treatments/types",
			icon: "deployment-unit",
			label: "Treatment Types",
		},
		{
			key: "/treatments/body-parts",
			link: "/treatments/body-parts",
			icon: "skin",
			label: "Body Parts",
		},
		{ key: "/reviews", link: "/reviews", icon: "solution", label: "Reviews" },

		{
			key: "/emails",
			link: "/emails",
			icon: "mail",
			label: "Email",
		},
	];

	const analystMenuItems: MenuItemInterface[] = [
		{ key: "/", link: "/", icon: "home", label: "Dashboard" },
		{ key: "/patients", link: "/patients", icon: "team", label: "Patients" },
		{ key: "/users", link: "/users", icon: "user", label: "Users" },
		{
			key: "/questionnaires",
			link: "/questionnaires",
			icon: "container",
			label: "Questionnaires",
		},
		{ key: "/treatments", link: "/treatments", icon: "medicine-box", label: "Treatments" },
		{
			key: "/treatments/types",
			link: "/treatments/types",
			icon: "deployment-unit",
			label: "Treatment Types",
		},
		{
			key: "/treatments/body-parts",
			link: "/treatments/body-parts",
			icon: "skin",
			label: "Body Parts",
		},
		{ key: "/reviews", link: "/reviews", icon: "solution", label: "Reviews" },
		{
			key: "/emails",
			link: "/emails",
			icon: "mail",
			label: "Email",
		},
	];

	function getMenuItems(role: string): MenuItemInterface[] {
		switch (role) {
			case "super-admin":
				return superAdminMenuItems;
			case "admin":
				return adminMenuItems;
			case "consultant":
				return consultantMenuItems;
			case "analyst":
				return analystMenuItems;
			default:
				return [];
		}
	}

	const menuItems: MenuItemInterface[] = getMenuItems(role);
	return menuItems;
}

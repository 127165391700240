import React from "react";
import Props from "./Icon.types";
import defaultProps from "./defaultProps";

const Phone = ({ size, fill, ...otherProps }: Props) => (
	<svg {...otherProps} viewBox="0 0 31.617 31.596" width={size} height={size} fill={fill}>
		<path d="M31.6 24.935a1.528 1.528 0 0 1-.469 1.339l-4.452 4.418a2.505 2.505 0 0 1-.786.569 3.25 3.25 0 0 1-.954.3q-.033 0-.2.017t-.435.017a15.431 15.431 0 0 1-2.059-.218 15.633 15.633 0 0 1-3.481-1.071 29.217 29.217 0 0 1-4.669-2.56 34.812 34.812 0 0 1-5.556-4.686 36.677 36.677 0 0 1-3.883-4.418 31.479 31.479 0 0 1-2.477-3.9 21 21 0 0 1-1.404-3.245 17.225 17.225 0 0 1-.636-2.51A8.29 8.29 0 0 1 .006 7.33q.033-.6.033-.669a3.253 3.253 0 0 1 .3-.954 2.508 2.508 0 0 1 .57-.787L5.361.47A1.474 1.474 0 0 1 6.432 0 1.249 1.249 0 0 1 7.2.251a2.327 2.327 0 0 1 .569.619l3.581 6.794a1.629 1.629 0 0 1 .167 1.171 2.086 2.086 0 0 1-.569 1.071l-1.64 1.64a.558.558 0 0 0-.117.218.826.826 0 0 0-.05.251 6.044 6.044 0 0 0 .6 1.607 14.748 14.748 0 0 0 1.238 1.958 21.74 21.74 0 0 0 2.376 2.661 22.17 22.17 0 0 0 2.678 2.393A15.463 15.463 0 0 0 18 21.889a5.2 5.2 0 0 0 1.2.485l.418.084a.8.8 0 0 0 .218-.05.559.559 0 0 0 .218-.117l1.908-1.941a2.05 2.05 0 0 1 1.406-.535 1.749 1.749 0 0 1 .9.2h.032l6.46 3.816a1.684 1.684 0 0 1 .84 1.104z"/>
	</svg>
);

Phone.defaultProps = defaultProps;

export default Phone;

import React from "react";
import Props from "./Icon.types";
import defaultProps from "./defaultProps";

const Email = ({ size, fill, ...otherProps }: Props) => (
	<svg {...otherProps} viewBox="0 0 36.171 25.837" width={size} height={size} fill={fill}>
		<path d="M18.086 18.085l-4.475-3.917L.817 25.136a2.619 2.619 0 0 0 1.785.7H33.57a2.6 2.6 0 0 0 1.778-.7L22.561 14.169z" />
		<path d="M35.355.7A2.6 2.6 0 0 0 33.57 0H2.602a2.591 2.591 0 0 0-1.78.705l17.264 14.8zM0 2.269v21.468l12.487-10.611zM23.685 13.122L36.172 23.73V2.26z" />
	</svg>
);

Email.defaultProps = defaultProps;

export default Email;

import React, { Fragment, useState } from "react";

import css from "./Treatments.module.css";

import { Button, Icon, Table, Popconfirm, message, Modal } from "antd";
import { TreatmentRecord, Patient } from "../../../models";
import { destroy } from "../../../services";
import { StoreState, StoreDispatch } from "../../../Store";

import CreateEditTreatment from "./CreateEditTreatment";

interface Props {
	treatments: TreatmentRecord[];
	initialTreatment: TreatmentRecord;
	getTreatments: () => void;
	patient: Patient;
}

const Treatments = ({ treatments, initialTreatment, getTreatments, patient }: Props) => {
	const state = React.useContext(StoreState);
	const dispatch = React.useContext(StoreDispatch);

	const treatment = state.patient.currentTreatment || initialTreatment;

	const [showModal, setShowModal] = useState<boolean>(false);
	const [editMode, setEditMode] = useState<boolean>(false);

	async function deleteTreatment(treatmentId: string) {
		try {
			const res = await destroy("/treatment-records/" + treatmentId);
			if (res.data) {
				message.success("Treatment successfully deleted");
				await getTreatments();
				dispatch({ type: "SET_TREATMENT", payload: res.data[0] });
			} else {
				message.error("Unexpected Server Error: Failed to delete treatment.");
			}
		} catch {
			message.error("Unexpected Server Error: Failed to delete treatment.");
		}
	}

	function onEdit(row: TreatmentRecord) {
		setEditMode(true);
		setShowModal(true);
	}

	function onModalClose() {
		setEditMode(false);
		setShowModal(false);
	}

	const columns = [
		{
			title: "Name",
			key: "treatment.treatmentType.name",
			render: (text: string, row: TreatmentRecord) => (
				<Fragment>
					{row.treatment.treatmentType.name} {row.treatment.bodyPart.name}
				</Fragment>
			),
		},
		{
			title: "Side",
			dataIndex: "side",
		},
		{
			title: "Start Date",
			key: "startDate",
			render: (text: string, row: TreatmentRecord) => (
				<Fragment>{new Date(row.startDate).toLocaleDateString()}</Fragment>
			),
		},
		{
			title: "Consultant",
			key: "consultant.id",
			render: (text: string, row: TreatmentRecord) => (
				<Fragment>
					{row.consultant.title} {row.consultant.firstName} {row.consultant.lastName}
				</Fragment>
			),
		},
		{
			title: "Location",
			key: "location.id",
			render: (text: string, row: TreatmentRecord) => (
				<Fragment>{row.location ? row.location.name : "no location"} </Fragment>
			),
		},
		{
			title: "",
			align: "right" as "right",
			key: "id",
			render: (text: string, row: TreatmentRecord) => (
				<Fragment>
					<Button size="default" type="link" onClick={() => onEdit(row)}>
						Edit
						<Icon type="edit" />
					</Button>

					<Popconfirm
						title="Are you sure delete this treatment? All associated scores will also be deleted."
						onConfirm={() => deleteTreatment(row.id)}
						okText="Yes"
						cancelText="No">
						<Button size="default" type="link" style={{ color: "#a20909" }}>
							Delete
							<Icon type="delete" />
						</Button>
					</Popconfirm>
				</Fragment>
			),
		},
	];

	return (
		<section className={css.treatments}>
			<h2>
				<Icon type="medicine-box" style={{ color: "#757b86" }} /> Treatments
			</h2>

			<Button type="primary" onClick={() => setShowModal(true)}>
				Add Treatment
			</Button>

			<Table
				rowKey="id"
				style={{ marginTop: 25 }}
				rowClassName={(record, index) =>
					[css.row, treatment.id === record.id ? css.active : ""].join(" ")
				}
				dataSource={treatments}
				columns={columns}
				pagination={false}
				onRow={(record, rowIndex) => {
					return {
						onClick: (event) => {
							const current = treatments[rowIndex];
							dispatch({ type: "SET_TREATMENT", payload: current });
						},
					};
				}}
			/>

			<Modal
				visible={showModal}
				onCancel={onModalClose}
				width={800}
				destroyOnClose
				footer={null}
				title={`${editMode ? "Edit" : "Create"} treatment`}>
				<CreateEditTreatment
					patient={patient}
					closeModal={onModalClose}
					getTreatments={getTreatments}
					initialValues={treatment}
					editMode={editMode}
				/>
			</Modal>
		</section>
	);
};

export default Treatments;

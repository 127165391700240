import React, { useEffect, FormEvent, useState } from "react";
import { Form, Select, message, Divider, Button, Icon } from "antd";
import { get, put } from "../../../services";
import { FormComponentProps } from "antd/lib/form";
import { Questionnaire, ReviewRecord, Review } from "../../../models";

interface Props extends FormComponentProps {
	closeModal: () => void;
	review: ReviewRecord | Review;
	getReviews: () => void;
}

interface Values {
	questionnaires: any[];
}

const AddQuestionnaire = ({ closeModal, form, review, getReviews }: Props) => {
	const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([]);
	const [loading, setLoading] = useState<boolean>(false);	

	const existingQuestionnaires = review.questionnaires.sort((a: Questionnaire, b: Questionnaire) =>
		a.name.localeCompare(b.name)
	);

	const { getFieldDecorator } = form;
	const { Option } = Select;

	async function getQuestionnaires() {
		try {
			const res = await get("/questionnaires");
			if (res.data) {
				setQuestionnaires(
					res.data.sort((a: Questionnaire, b: Questionnaire) => a.name.localeCompare(b.name))
				);
			} else {
				throw new Error("Request failed");
			}
		} catch {
			message.error("Unexpected Server Error: Failed to load questionnaires.");
		}
	}

	useEffect(() => {
		getQuestionnaires();
	}, []);

	function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		form.validateFieldsAndScroll(async (err, values: Values) => {
			if (!err) {
				setLoading(true);
				// Merge existing questionnaires with new ones
				review.questionnaires.forEach((questionnaire) => {
					values.questionnaires.push(questionnaire.id);
				});

				try {
					const res = ("treatmentRecordId" in review ) ? await put("/review-records/" + review.id, {
						questionnaires: values.questionnaires
					}) : await put("/reviews/" + review.id, {
						questionnaires: values.questionnaires
					}) ;

					if (res.data) {
						getReviews();
						closeModal();
						message.success("Successfully added new questionnaires");
					} else {
						message.error("Unexpected server error: could not add questionnaires");
					}
					setLoading(false);
				} catch (error) {
					message.error("Unexpected server error: could not add questionnaires");
					setLoading(false);
				}
			}
		});
	}

	return (
		<Form layout="vertical" onSubmit={(event) => handleSubmit(event)}>
			<Form.Item label="Select questionnaire(s)">
				{getFieldDecorator("questionnaires", {
					rules: [
						{
							required: true,
							message: "Please select a questionnaire"
						}
					]
				})(
					<Select
						showSearch
						optionFilterProp="children"
						filterOption={(input: any, option: any) =>
							option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						mode="multiple"
						placeholder="Select questionnaire"
						allowClear
						dropdownRender={(menu) => (
							<div>
								{menu}
								<Divider style={{ margin: "10px 0 0 0" }} />
								<Button
									type="primary"
									style={{ margin: 0, borderRadius: 0 }}
									block
									icon="check"
									htmlType="button">
									Done
								</Button>
							</div>
						)}
						loading={!questionnaires.length ? true : false}>
						<Option disabled value="">
							Select questionnaire
						</Option>
						{questionnaires
							// Filter out existing questionnaires so you can't duplicate
							.filter((q: Questionnaire) =>
								existingQuestionnaires.every((eq: Questionnaire) => eq.id !== q.id)
							)
							.map((questionnaire: Questionnaire) => (
								<Option key={questionnaire.id} value={questionnaire.id}>
									{questionnaire.name}
								</Option>
							))}
					</Select>
				)}
			</Form.Item>

			<Form.Item style={{ marginBottom: 0, textAlign: "right" }}>
				<Button style={{ marginRight: 10 }} size="large" htmlType="button" onClick={closeModal}>
					Cancel
				</Button>

				<Button size="large" loading={loading} type="primary" htmlType="submit" disabled={loading}>
					Add Questionnaires
				</Button>
			</Form.Item>
		</Form>
	);
};

export default Form.create<Props>()(AddQuestionnaire);

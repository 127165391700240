import API from "../lib/axiosConfig";

import { Response } from "../models";
export default async function put(endpoint: string, data: {}, headers?: {}) {
	try {
		const response = await API.put(endpoint, data, { headers });
		return response as Response;
	} catch (error) {
		return error as Response;
	}
}

import API from "../lib/axiosConfig";

import { Response } from "../models";
export default async function get(endpoint: string, include?: string) {
	try {
		const query = include ? `?include=${include}` : "";
		const response = await API.get(endpoint + query);
		return response as Response;
	} catch (error) {
		console.log(error);
		throw error;
	}
}

import React, { useState, useEffect, Fragment, useContext } from "react";
import { get, destroy } from "../../services";
import SearchBar from "../../components/searchbar/SearchBar";
import css from "./css/Patient.module.css";

// Components
import {
	Button,
	Icon,
	Table,
	message,
	Popconfirm,
	Tag,
	Row,
	Dropdown,
	Menu,
	Input,
	Select,
	Divider,
} from "antd";
import Layout from "../../components/layouts/Main";
import { Location as LocationInterface, Patient } from "../../models";
import { Link } from "react-router-dom";
import moment from "moment";
import { stringToColour } from "../../lib/stringToColor";
import { StoreState } from "../../Store";

const Patients = () => {
	const [patients, setPatients] = useState<Patient[]>([]);
	const [locations, setlocations] = useState<LocationInterface[]>([]);
	const [patientCount, setPatientCount] = useState<number>();
	const [searchParam, setSearchParam] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(true);
	const state = useContext(StoreState);

	const { user } = state;
	const { Option } = Select;

	async function getPatients(searchParam: string = " ") {
		try {
			const res = await get(
				`/patients?include=locations,clinic,primaryConsultant,researchAnalyst&search=${searchParam}`
				//`/patients?include=locations,clinic,researchAnalyst&search=${searchParam}&page=${page}&specifiedLocation=${specifiedLocation}`
			);
			setPatients(res.data.patients);
		} catch {
			message.error("Unexpected Server Error: Failed to load patient data.");
		}
		setLoading(false);
	}

	async function getLocations() {
		try {
			const res = await get(`/locations/`);
			setlocations(res.data);
		} catch (err) {
			message.error("Unexpected Server Error: Failed to load patient data.");
			console.log(err);
		}
		setLoading(false);
	}

	async function deletePatient(patientId: string) {
		try {
			const res = await destroy("/patients/" + patientId);
			if (res.data) {
				const updatedPatients = patients.filter((patient: Patient) => patient.id !== patientId);
				setPatients(updatedPatients);
				message.success("Patient successfully deleted");
			} else {
				message.error("Unexpected Server Error: Failed to delete patient.");
			}
		} catch (error) {
			message.error("Unexpected Server Error: Failed to delete patient.");
		}
	}

	// Get data on mount
	useEffect(() => {
		getPatients();
		getLocations();
	}, []);

	useEffect(() => {
		if (user && user.role === "super-admin") {
			getPatients();
			getLocations();
		}
	}, [user]);

	const columns = [
		{
			title: "Title",
			key: "patient.title",
			sorter: (a: Patient, b: Patient) => a.title.localeCompare(b.title),
			render: (text: string, row: Patient) => <Fragment>{row.title}</Fragment>,
		},
		{
			title: "First Name",
			key: "patient.firstName",
			sorter: (a: Patient, b: Patient) => a.firstName.localeCompare(b.firstName),
			render: (text: string, row: Patient) => (
				<div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>{row.firstName}</div>
			),
		},
		{
			title: "Last Name",
			key: "patient.lastName",

			sorter: (a: Patient, b: Patient) => a.lastName.localeCompare(b.lastName),
			render: (text: string, row: Patient) => (
				<div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>{row.lastName}</div>
			),
		},
		{
			title: "Locations",
			key: "patient.Locations",
			dataIndex: "locations",
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
				setVisible,
			}: any) => (
				<div style={{ padding: 8 }}>
					<Select
						mode="multiple"
						placeholder="Select locations"
						value={selectedKeys}
						onChange={(selected: any) => setSelectedKeys(selected)}
						style={{ width: 250, marginBottom: 8, display: "block" }}
						dropdownRender={(menu) => (
							<div>
								{menu}
								<Divider style={{ margin: "10px 0 0 0" }} />
								<Button
									type="primary"
									style={{ margin: 0, borderRadius: 3 }}
									onClick={() => setVisible(false)}
									size="small"
									block>
									Done
								</Button>
							</div>
						)}>
						{locations.map((location) => (
							<Option key={location.id} value={location.id}>
								{location.name}
							</Option>
						))}
					</Select>
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<Button type="primary" onClick={confirm} size="small" style={{ marginRight: 8 }}>
							Filter
						</Button>
						<Button onClick={clearFilters} size="small">
							Reset
						</Button>
					</div>
				</div>
			),
			onFilter: (value: any, record: any) =>
				record.locations.some((location: LocationInterface) => value.includes(location.id)),
			render: (locations: LocationInterface[]) => (
				<>
					{locations.map((location: LocationInterface) => (
						<Tag color={stringToColour((location && location.name) || "")} key={location.id}>
							{location.name}
						</Tag>
					))}
				</>
			),
		},
		{
			title: "Email",
			key: "patient.email",

			sorter: (a: Patient, b: Patient) => {
				const emailA = a.email || "";
				const emailB = b.email || "";
				return emailA.localeCompare(emailB);
			},
			render: (text: string, row: Patient) => (
				<div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>{row.email}</div>
			),
		},
		{
			title: "Additional Reference",
			key: "patient.additionalReference",

			sorter: (a: Patient, b: Patient) =>
				a.hospitalRef.toLowerCase() > b.hospitalRef.toLowerCase() ? 1 : -1,
			render: (text: string, row: Patient) => (
				<div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>{row.hospitalRef}</div>
			),
		},
		{
			title: "Date of Birth",
			key: "patient.dob",

			sorter: (a: Patient, b: Patient) => moment(a.dob).unix() - moment(b.dob).unix(),
			render: (text: string, row: Patient) => <div>{moment(row.dob).format("DD/MM/YYYY")}</div>,
		},
		{
			title: "Created At",
			key: "patient.createdAt",
			sorter: (a: Patient, b: Patient) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
			render: (text: string, row: Patient) => (
				<Fragment>{moment(row.createdAt).format("DD/MM/YYYY").toString()}</Fragment>
			),
		},
		{
			title: "Assigned Analyst",
			key: "patient.researchAnalyst",
			sorter: (a: Patient, b: Patient) => {
				const analystA =
					(a.researchAnalyst && `${a.researchAnalyst.firstName} ${a.researchAnalyst.lastName}`) ||
					"";
				const analystB =
					(b.researchAnalyst && `${b.researchAnalyst.firstName} ${b.researchAnalyst.lastName}`) ||
					"";
				return analystA.localeCompare(analystB);
			},
			render: (text: string, row: Patient) => (
				<Fragment>
					<Tag color={stringToColour((row.researchAnalyst && row.researchAnalyst.email) || "")}>
						{row.researchAnalyst &&
							`${row.researchAnalyst.title} ${row.researchAnalyst.firstName} ${row.researchAnalyst.lastName}`}
					</Tag>
				</Fragment>
			),
		},
		{
			title: "",
			align: "right" as "right",
			key: "id",
			render: (text: string, row: Patient) => (
				<Fragment>
					<Link to={"/patients/" + row.id}>
						<Button size="default" type="link">
							View
							<Icon type="eye" />
						</Button>
					</Link>
					<Link to={"/patients/edit/" + row.id}>
						<Button size="default" type="link">
							Edit
							<Icon type="edit" />
						</Button>
					</Link>
					<Popconfirm
						title="Are you sure delete this patient?"
						onConfirm={() => deletePatient(row.id)}
						okText="Yes"
						cancelText="No">
						<Button size="default" type="link" style={{ color: "#a20909" }}>
							Delete
							<Icon type="delete" />
						</Button>
					</Popconfirm>
				</Fragment>
			),
		},
	];

	return (
		<Layout title="Patients" pagetitle="Patients">
			<Link to="/patients/new">
				<Button type="primary" size="default" icon="plus">
					Add new patient
				</Button>
			</Link>
			<SearchBar getPatients={getPatients} setSearchParam={setSearchParam}></SearchBar>
			<Table
				className="patients-table"
				loading={loading}
				rowKey="id"
				style={{ marginTop: 25 }}
				rowClassName={(record, index) => [css.row, record.id ? css.active : ""].join(" ")}
				dataSource={patients}
				columns={columns}
				pagination={{ pageSize: 100 }}
			/>
		</Layout>
	);
};

export default Patients;

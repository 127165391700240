import React, { useState, useEffect, useContext, ChangeEvent, FormEvent } from "react";
import { Questionnaire } from "../../models";
import { get, post } from "../../services";
import Layout from "../../components/layouts/Main";
import { List } from "antd";

const Questionnaires = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const [showCreateEditModal, setShowCreateEditModal] = useState<boolean>(false);
	const [selected, setSelected] = useState<any>(null);
	const [editMode, setEditMode] = useState<boolean>(false);
	const [name, setName] = useState<string>("");
	const [questionnaires, setQuestionnaires] = useState<any[]>([]);

	// Fetch data
	const getData = async () => {
		const questionnairesRes = await get("/questionnaires");
		setQuestionnaires(questionnairesRes.data);

		setLoading(false);
	};

	// POST/PUT data
	const createEditQuestionnaire = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const values = new FormData(event.currentTarget as HTMLFormElement);

		const reqData = {
			name: values.get("name")
		};

		// Add new items to state so table is updated without refresh
		// if (res && data) {
		// 	setShowCreateEditModal(false);
		// 	setEditMode(false);

		// 	// Add new questionnaire for POST, replace for PUT
		// 	// This is so we don't have to fire another network request for data
		// 	if (editMode) {
		// 		const removedOutdated = questionnaires.filter((item: any) => item.id !== selected.id);

		// 		setQuestionnaires([...removedOutdated, data]);
		// 	} else {
		// 		setQuestionnaires([...questionnaires, data]);
		// 	}
		// }
	};

	// Get data on mount
	useEffect(() => {
		getData();
	}, []);

	//Close Modal
	const closeModal = () => {
		setShowCreateEditModal(false);
		setEditMode(false);
		setSelected(null);
		setName("");
	};

	const questionnaireOptions = questionnaires.map((questionnaire: Questionnaire) => {
		return {
			id: questionnaire.id,
			name: questionnaire.id,
			label: questionnaire.name
		};
	});

	return (
		<Layout title="Questionnaires" pagetitle="Questionnaires">
			{/* <Button
				type="button"
				text={"Create new questionnaire"}
				classes={["small"]}
				onClick={() => setShowCreateEditModal(true)}
			/> */}

			<List
				loading={loading}
				bordered
				dataSource={questionnaires.sort((q1: Questionnaire, q2: Questionnaire) =>
					q1.name.localeCompare(q2.name)
				)}
				renderItem={(item) => <List.Item>{item.name}</List.Item>}
			/>

			{/* Create new questionnaire modal */}
			{/* <Modal
				small
				show={showCreateEditModal}
				onClose={closeModal}
				title={editMode ? "Edit Questionnaire" : "Add new questionnaire"}>
				<form onSubmit={(event) => createEditQuestionnaire(event)}>
					<Select
						label="Name"
						name="name"
						required={true}
						options={questionnaireOptions}
						onChange={(event: ChangeEvent<HTMLSelectElement>) => setName(event.currentTarget.value)}
					/>

					<Button
						type="submit"
						text={`${editMode ? "Save" : "Create"} Questionnaire`}
						disabled={editMode ? selected.name === name : name === ""}
					/>
				</form>
			</Modal> */}
		</Layout>
	);
};

export default Questionnaires;

import React, { FormEvent, useEffect, useState } from "react";
import { FormComponentProps } from "antd/lib/form";
import { Form, Select, Skeleton, Input, Row, Col, Button, message } from "antd";
import { TreatmentType, BodyPart, Treatment } from "../../models";
import { get, put, post } from "../../services";

interface Values {
	treatmentTypeId: string;
	bodyPartId: string;
}

interface Props extends FormComponentProps {
	treatment: Treatment | null;
	closeDrawer: () => void;
	getData: () => void;
	editing: boolean;
}

const CreateEditTreatmentTypeForm = ({ form, treatment, closeDrawer, getData }: Props) => {
	const [loading, setLoading] = useState<boolean>(false);	

	const [treatmentTypes, setTreatmentTypes] = useState<TreatmentType[]>([]);
	const [bodyParts, setBodyParts] = useState<BodyPart[]>([]);

	const { getFieldDecorator } = form;
	const { Option } = Select;

	async function getTreatmentTypes() {
		try {
			const { data } = await get("/treatment-types");
			setTreatmentTypes(data);
		} catch (error) {
			console.log(error);
			message.error("Unexpected Server Error: Failed to load treatment types data.");
		}
	}

	async function getBodyParts() {
		try {
			const { data } = await get("/body-parts");
			setBodyParts(data);
		} catch (error) {
			console.log(error);
			message.error("Unexpected Server Error: Failed to load body parts data.");
		}
	}

	useEffect(() => {
		
		getTreatmentTypes();
		getBodyParts();
	}, []);

	function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		form.validateFieldsAndScroll(async (err, values: Values) => {
			if (!err) {
				setLoading(true);
				const res = treatment ? await put("/treatments/" + treatment.id, values) : await post("/treatments", values);
				if (res.data) {
					await getData();
					closeDrawer();
					message.success(`Successfully ${treatment ? "updated" : "created"} treatment`);
				} else {
					if (res.statusCode === 409) {
						message.error("A treatment type with this treatment type and body part already exists.");
					} else {
						message.error("Unexpected Server Error: Failed to save treatment data.");
					}
				}

				setLoading(false);
			}
		});
	}

	if (!treatmentTypes || !bodyParts ) {
		return <Skeleton active />;
	}

	return (
		<Form autoComplete="off" layout="vertical" onSubmit={(event) => handleSubmit(event)}>
			<Row gutter={24}>
				<Col span={12}>
					<Form.Item label="Treatment type">
						{getFieldDecorator("treatmentTypeId", {
							...(treatment && { initialValue: treatment.treatmentTypeId }),
							trigger: "onBlur",
							valuePropName: "defaultValue"
						})(
							<Select
								allowClear
								showSearch
								optionFilterProp="children"
								filterOption={(input: any, option: any) =>
									option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								placeholder="Select treatment type"
								disabled={!treatmentTypes.length}
								loading={!treatmentTypes.length ? true : false}>
								<Option disabled value="">
									Select treatment type
								</Option>
								{treatmentTypes
									.map((treatmentType: TreatmentType) => {
										return (
											<Option key={treatmentType.id} value={treatmentType.id}>
												{treatmentType.name}
											</Option>
										);
									})}
							</Select>
						)}
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item label="Body part">
						{getFieldDecorator("bodyPartId", {
							...(treatment && { initialValue: treatment.bodyPartId }),
							trigger: "onBlur",
							valuePropName: "defaultValue"
						})(
							<Select
								allowClear
								showSearch
								optionFilterProp="children"
								filterOption={(input: any, option: any) =>
									option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								placeholder="Select body part"
								disabled={!bodyParts.length}
								loading={!bodyParts.length ? true : false}>
								<Option disabled value="">
									Select body part
								</Option>
								{bodyParts
									.map((bodyPart: BodyPart) => {
										return (
											<Option key={bodyPart.id} value={bodyPart.id}>
												{bodyPart.name}
											</Option>
										);
									})}
							</Select>
						)}
					</Form.Item>
				</Col>
			</Row>

			<Form.Item>
				<Button
					loading={loading}
					disabled={loading}
					size="large"
					type="primary"
					htmlType="submit"
					block>
					Save
				</Button>
			</Form.Item>
		</Form>
	);
};

export default Form.create<Props>()(CreateEditTreatmentTypeForm);

import React, { useState, useEffect, Fragment, useContext } from "react";
import { destroy, get, post, put } from "../../services";
import Layout from "../../components/layouts/Main";
import { Button, Table, Tag, Icon, message, Checkbox, Popconfirm } from "antd";
import { Email } from "../../models/Email";
import { Link } from "react-router-dom";
import patch from "../../services/patch";
import { StoreState } from "../../Store";
// import EditEmailForm from "../../components/emails/EditEmailForm";

const Emails = () => {
	const [emails, setEmails] = useState<Email[]>([]);
	const [loading, setLoading] = useState(true);
	const [showEdit, setShowEdit] = useState(false);
	const [currentEmail, setCurrentEmail] = useState<string | null>(null);
	const [editing, setEditing] = useState(false);
	const state = useContext(StoreState);
	const { user } = state;

	const getEmails = async () => {
		try {
			const res = await get("/mails/?include=email");
			setEmails(res.data);
		} catch (error) {
			message.error("Unexpected Server Error: Failed to load email data.");
		}
		setLoading(false);
	};

	async function deleteEmail(emailId: string) {
		try {
			const res = await destroy("/mails/" + emailId);
			if (res.status === 200) {
				setEmails((prevEmails) => prevEmails.filter((email: Email) => email.id !== emailId));
				message.success("Email successfully deleted");
				return;
			}
			if (res.status === 409) {
				message.error(res.message);
				return;
			} else {
				message.error("Unexpected Server Error: Failed to delete Email.");
			}
		} catch (error) {
			message.error("Error while deleting Email");
			console.log(error);
		}
	}
	const handleDefaultEmailChange = async (email: Email) => {
		const res = await patch(`mails/${email.id}/setAsDefault`);
		if (res.status === 200) {
			message.success(
				"Default email changed. Please note that your email to review set up may have changed."
			);
		}
		getEmails();

		// const previouslyDefaultEmail: Email | undefined = emails.find(
		// 	(email: Email) => email.isDefault
		// );

		// if (previouslyDefaultEmail && previouslyDefaultEmail.id !== email.id) {
		// 	const previousDefaultEmail = { ...previouslyDefaultEmail, isDefault: false };
		// 	const newDefaultEmail = { ...email, isDefault: true };

		// 	const response = await put(
		// 		`mails/updateDefaultEmail/${email.id}/${previousDefaultEmail.id}`,
		// 		[]
		// 	);

		// 		const updatedEmails = emails.map((e: Email) => {
		// 			if (e.id === previousDefaultEmail.id) {
		// 				return previousDefaultEmail;
		// 			}
		// 			if (e.id === newDefaultEmail.id) {
		// 				return newDefaultEmail;
		// 			}
		// 			return { ...e, isDefault: false };
		// 		});

		// setEmails(updatedEmails);
		// }
	};
	// };

	const onEdit = (emailId: string) => {
		setEditing(true);
		setCurrentEmail(emailId);
		setShowEdit(true);
	};

	const onNew = () => {
		setEditing(false);
		setShowEdit(true);
	};

	useEffect(() => {
		getEmails();
	}, []);

	useEffect(() => {
		if (user && user.role === "super-admin") {
			getEmails();
		}
	}, [user]);

	const columns = [
		{
			title: "Email Name",
			key: "email.emailName",
			render: (text: string, row: Email) => <Fragment>{row.emailName}</Fragment>,
			sorter: (a: Email, b: Email) => a.emailName.localeCompare(b.emailName),
		},
		{
			title: "From Address",
			key: "email.fromAddress",
			render: (text: string, row: Email) => <Fragment>{row.fromAddress}</Fragment>,
			sorter: (a: Email, b: Email) => a.fromAddress.localeCompare(b.fromAddress),
		},
		{
			title: "Is Default",
			align: "right" as "right",
			key: "email.isDefault",
			render: (text: string, row: Email) => (
				<div onClick={() => handleDefaultEmailChange(row)} style={{ cursor: "pointer" }}>
					<Checkbox checked={row.isDefault} disabled={emails.length < 2 ? true : false} />
				</div>
			),
		},
		{
			title: "",
			align: "right" as "right",
			key: "id",
			render: (text: string, row: Email) => (
				<Fragment>
					<Link to={"/emails/edit/" + row.id}>
						<Button size="default" type="link" onClick={() => onEdit(row.id)}>
							Edit
							<Icon type="edit" />
						</Button>
					</Link>
					<Popconfirm
						title="Are you sure delete this email?"
						onConfirm={() => deleteEmail(row.id)}
						okText="Yes"
						cancelText="No">
						{emails.length === 1 && row.isDefault ? (
							<Button size="default" type="link" style={{ color: "#a20909" }}>
								Delete
								<Icon type="delete" />
							</Button>
						) : (
							emails.length > 1 &&
							!row.isDefault && (
								<Button size="default" type="link" style={{ color: "#a20909" }}>
									Delete
									<Icon type="delete" />
								</Button>
							)
						)}
					</Popconfirm>
				</Fragment>
			),
		},
	];
	return (
		<Layout title="Emails" pagetitle="Emails">
			{emails.length < 3 ? (
				<Link to="/emails/new">
					<Button type="primary" size="default" icon="plus" onClick={onNew}>
						Add new email
					</Button>
				</Link>
			) : null}

			<Table
				loading={loading}
				rowKey="id"
				style={{ marginTop: 25 }}
				dataSource={emails}
				columns={columns}
				pagination={{ pageSize: 10 }}
			/>
		</Layout>
	);
};

export default Emails;

import React, { FormEvent, useState, useEffect } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { Form, Icon, Input, Button, Checkbox, message } from "antd";
import { FormComponentProps } from "antd/lib/form";
import styles from "./Login.module.css";
import Layout from "../../components/layouts/Unauthenticated";
import { post } from "../../services";
import Cookies from "js-cookie";
import { getUrlParameter } from "../../lib";

const Login: React.FC<RouteComponentProps & FormComponentProps> = ({ location, form }) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [redirectToReferrer, setRedirectToReferrer] = useState<boolean>(false);

	useEffect(() => {
		const params = location.search;
		const sessionTimeout = getUrlParameter("sessionTimeout", params) || null;

		// Show expired token message
		if (sessionTimeout === "1") {
			message.info("Your session has timed out. Please log in again.");
		}
	}, [location.search]);

	interface Values {
		email: string;
		password: string;
		remember: boolean;
	}

	const submit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		form.validateFields(async (err, values) => {
			if (!err) {
				setLoading(true);
				const loginRes = await post("/auth/login", values);
				setLoading(false);
				if (loginRes.data) {
					// Set session Cookie if remember not ticked
					if (!values.remember) {
						Cookies.set("powerBILInk", loginRes.data.powerBilink);
						Cookies.set("token", loginRes.data.token, {
							secure: process.env.NODE_ENV === "production",
						});
					}
					message.success("Successfully logged in");
					setRedirectToReferrer(true);
				} else {
					message.error(loginRes.message ? loginRes.message : "Unknown error");
				}
			}
		});
	};

	const { from } = location.state || { from: { pathname: "/" } };
	const { getFieldDecorator } = form;

	if (redirectToReferrer) {
		return <Redirect to={from} />;
	}

	return (
		<Layout pageTitle="Login">
			<h1 className={styles.title}>Login</h1>
			<Form onSubmit={(event) => submit(event)} className="login-form">
				<Form.Item>
					{getFieldDecorator("email", {
						rules: [{ required: true, message: "Please input your email!" }],
					})(
						<Input
							prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
							placeholder="Email"
						/>
					)}
				</Form.Item>
				<Form.Item>
					{getFieldDecorator("password", {
						rules: [{ required: true, message: "Please input your password!" }],
					})(
						<Input
							prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
							type="password"
							placeholder="Password"
						/>
					)}
				</Form.Item>
				<Form.Item className={styles.buttons}>
					{getFieldDecorator("remember", {
						valuePropName: "checked",
						initialValue: true,
					})(<Checkbox>Remember me</Checkbox>)}
					<Button
						size="large"
						type="primary"
						htmlType="submit"
						loading={loading}
						className={styles.loginButton}>
						Log in
					</Button>
				</Form.Item>
			</Form>
		</Layout>
	);
};

export default Form.create()(Login);

import React, { useState, FormEvent, useEffect, useContext } from "react";
import { Row, Col, Form, Select, Input, Button, Radio, message } from "antd";
import { FormComponentProps } from "antd/lib/form";
import moment from "moment";
import { UKcounties, countries } from "../../lib";
import { get, post, put } from "../../services";
import { Redirect } from "react-router";
import { Clinic, Patient, User, Location } from "../../models";
import { StoreState } from "../../Store";

interface Props extends FormComponentProps {
	patient: null | Patient;
}

const CreateEditPatientForm = ({ form, patient }: Props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [users, setUsers] = useState<User[]>([]);
	const [locations, setLocations] = useState<Location[]>([]);
	const [patientLocations, setPatientLocations] = useState<string[]>([]);
	const [otherTitle, setOtherTitle] = useState<boolean>(false);
	const [age, setAge] = useState<number | null>(null);
	const [createdUser, setCreatedUser] = useState<User | null>(null);
	const [allClinics, setAllClinics] = useState<Clinic[]>([]);
	const state = useContext(StoreState);

	const { user } = state;

	const { getFieldDecorator, setFieldsValue } = form;
	const { Option } = Select;
	const { TextArea } = Input;

	async function getUsers() {
		try {
			const res = await get("/users");
			if (res.data) {
				setUsers(res.data.users);
			} else {
				message.error("Unexpected Server Error: Failed to load users.");
			}
		} catch {
			message.error("Unexpected Server Error: Failed to load users.");
		}
	}

	async function getPatientLocations() {
		if (patient && patient.locations.length > 0) {
			patient.locations.forEach((location: any) => {
				setPatientLocations((prevState) => [...prevState, location.name]);
			});
		} else {
		}
	}

	async function getLocations() {
		try {
			const res = await get("/locations");
			if (res.data) {
				setLocations(res.data);

				// if (!patient || patient.locations.length === 0) {
				// 	res.data.forEach((location: any) => {
				// 		setPatientLocations((prevState) => [...prevState, location.name]);
				// 	});
				// }
			} else {
				message.error("Unexpected Server Error: Failed to load users.");
			}
		} catch {
			message.error("Unexpected Server Error: Failed to load users.");
		}
	}

	function isValidDate(rule: Object, value: string, callback: any) {
		try {
			// dd/mm/yyyy regex: https://stackoverflow.com/a/15504877
			var regex = new RegExp(
				/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
			);

			if (!regex.exec(value)) {
				throw new Error("Invalid Date!");
			}

			callback();
		} catch (err) {
			callback(err);
		}
	}
	function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		form.validateFieldsAndScroll(async (err, values) => {
			if (!err) {
				setLoading(true);
				const phones = JSON.stringify({
					mobile: values.mobilePhone,
					home: values.homePhone,
					work: values.workPhone,
				});

				if (!values.locations) {
					values.locations = patientLocations;
				}

				const locationIds = locations
					.filter((location: any) => values.locations.includes(location.name))
					.map((el) => ({ id: el.id }));

				const defaultLocationIds = locations.map((location) => location.id);

				if (values.locations.length === 0) {
					values.locations = defaultLocationIds;
				} else {
					values.locations = locationIds;
				}
				const payload = { ...values, phones };

				const res = patient
					? await put("/patients/" + patient.id, payload)
					: await post("/patients", payload);

				if (res.data) {
					message.success(`Patient successfully ${patient ? "updated" : "created"}`);
					setCreatedUser(res.data);
				} else {
					message.error("Unexpected Server Error: Failed to save patient.");
				}

				setLoading(false);
			} else {
				message.info("There are errors on the form. Please fix them before continuing.");
			}
		});
	}

	function handleTitleChange(value: string) {
		setOtherTitle(value === "Other" ? true : false);
		setFieldsValue({ title: value === "Other" ? "" : value });
	}

	function handleCountryChange(value: string) {
		setFieldsValue({ country: value });
	}

	useEffect(() => {
		getPatientLocations();
		getLocations();
		getUsers();
	}, []);

	if (createdUser) {
		return <Redirect to={"/patients/" + createdUser.id} />;
	}

	return (
		// trigger: "onBlur", valuePropName: 'defaultValue', for performance
		<Form onSubmit={(event) => handleSubmit(event)} autoComplete="off">
			<Row gutter={44}>
				<Col span={12}>
					<section>
						<h2>Personal Details</h2>
						<Row gutter={24}>
							<Col span={6}>
								<Form.Item label="Title">
									{getFieldDecorator("otherTitle", {
										...(patient && { initialValue: patient.title }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
										rules: [
											{
												required: otherTitle ? false : true,
												message: "Please select a title",
											},
										],
									})(
										<Select allowClear dropdownClassName="longer-list" onChange={handleTitleChange}>
											<Option value="Mr">Mr</Option>
											<Option value="Mrs">Mrs</Option>
											<Option value="Miss">Miss</Option>
											<Option value="Ms">Ms</Option>
											<Option value="Dr">Dr</Option>
											<Option value="Master">Master</Option>
											<Option value="Professor">Professor</Option>
											<Option value="Mx">Mx</Option>
											<Option value="Unspecified">Unspecified</Option>
											<Option value="Other">Other</Option>
										</Select>
									)}
								</Form.Item>
								<Form.Item style={{ display: otherTitle ? "block" : "none" }}>
									{getFieldDecorator("title", {
										...(patient && { initialValue: patient.title }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
										rules: [
											{
												required: otherTitle ? true : false,
												message: "Please enter a title",
											},
										],
									})(<Input placeholder="Enter title..." />)}
								</Form.Item>
							</Col>

							<Col span={6}>
								<Form.Item label="First name">
									{getFieldDecorator("firstName", {
										...(patient && { initialValue: patient.firstName }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
										rules: [
											{
												required: true,
												message: "Please enter a first name",
											},
										],
									})(<Input />)}
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item label="Last name">
									{getFieldDecorator("lastName", {
										...(patient && { initialValue: patient.lastName }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
										rules: [
											{
												required: true,
												message: "Please enter a last name",
											},
										],
									})(<Input />)}
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item label="Also known as">
									{getFieldDecorator("aka", {
										...(patient && { initialValue: patient.aka }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
										rules: [
											{
												required: false,
											},
										],
									})(<Input />)}
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={10}>
								<Form.Item label="Date of birth">
									{getFieldDecorator("dob", {
										...(patient && { initialValue: moment(patient.dob).format("DD/MM/YYYY") }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
										rules: [
											{
												required: true,
												message: "Please enter a valid date of birth",
												validator: isValidDate,
											},
										],
									})(
										<Input
											addonAfter={`${age ? age : ""} ${age ? "years old" : ""}`}
											onBlur={(event) =>
												setAge(
													moment().diff(moment(event.currentTarget.value, "DD/MM/YYYY"), "years")
												)
											}
											placeholder="DD/MM/YYYY"
											pattern="(0[1-9]|[12][0-9]|3[01])[/-]((0[1-9])|(1[0-2]))[/-]([12][0-9]{3})"
										/>
									)}
								</Form.Item>
							</Col>

							<Col span={14}>
								<Form.Item label="Sex">
									{getFieldDecorator("gender", {
										...(patient && { initialValue: patient.gender }),
										rules: [
											{
												required: true,
												message: "Please enter a sex",
											},
										],
									})(
										<Radio.Group size="large">
											<Radio value="Male">Male</Radio>
											<Radio value="Female">Female</Radio>
											<Radio value="Unspecified">Unspecified</Radio>
										</Radio.Group>
									)}
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label="Address">
									{getFieldDecorator("addressLine1", {
										...(patient && { initialValue: patient.addressLine1 }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
									})(<Input.TextArea placeholder="Address line 1" style={{ height: "9vh" }} />)}
								</Form.Item>
								<Form.Item>
									{getFieldDecorator("addressLine2", {
										...(patient && { initialValue: patient.addressLine2 }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
									})(<Input.TextArea placeholder="Address line 2" style={{ height: "9vh" }} />)}
								</Form.Item>
								<Form.Item>
									{getFieldDecorator("addressLine3", {
										...(patient && { initialValue: patient.addressLine3 }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
									})(<Input.TextArea placeholder="Address line 3" style={{ height: "9vh" }} />)}
								</Form.Item>

								<Row gutter={24}>
									<Col span={12}>
										<Form.Item label="Town/City">
											{getFieldDecorator("town", {
												...(patient && { initialValue: patient.town }),
												trigger: "onBlur",
												valuePropName: "defaultValue",
											})(<Input />)}
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="County">
											{getFieldDecorator("county", {
												...(patient && { initialValue: patient.county }),
												trigger: "onBlur",
												valuePropName: "defaultValue",
											})(
												<Select allowClear dropdownClassName="longer-list">
													{UKcounties.map((county: string) => (
														<Option key={county} value={county}>
															{county}
														</Option>
													))}
												</Select>
											)}
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Post code">
											{getFieldDecorator("postCode", {
												...(patient && { initialValue: patient.postCode }),
												trigger: "onBlur",
												valuePropName: "defaultValue",
											})(<Input />)}
										</Form.Item>
									</Col>
								</Row>

								<Form.Item label="Country">
									{getFieldDecorator("country", {
										...(patient && { initialValue: patient.country }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
										initialValue: patient ? patient.country : "GB",
									})(
										<Select
											allowClear
											dropdownClassName="longer-list"
											onChange={handleCountryChange}>
											{countries.map((country) => (
												<Option key={country.id} value={country.value}>
													{country.label}
												</Option>
											))}
										</Select>
									)}
								</Form.Item>
							</Col>

							<Col span={12}>
								<Row gutter={24}>
									<Col span={12}>
										<Form.Item label="Employment status">
											{getFieldDecorator("status", {
												...(patient && { initialValue: patient.status }),
												trigger: "onBlur",
												valuePropName: "defaultValue",
											})(
												<Select allowClear dropdownClassName="longer-list">
													<Option value="Employed">Employed</Option>
													<Option value="Self-employed">Self-employed</Option>
													<Option value="Out of work">Out of work</Option>
													<Option value="Homemaker">Homemaker</Option>
													<Option value="Student">Student</Option>
													<Option value="Retired">Retired</Option>
													<Option value="Unable to work">Unable to work</Option>
													<Option value="Unspecified">Unspecified</Option>
												</Select>
											)}
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Marital status">
											{getFieldDecorator("maritalStatus", {
												...(patient && { initialValue: patient.maritalStatus }),
												trigger: "onBlur",
												valuePropName: "defaultValue",
											})(
												<Select allowClear dropdownClassName="longer-list">
													<Option value="Married">Married</Option>
													<Option value="Partnership">Partnership</Option>
													<Option value="Widowed">Widowed</Option>
													<Option value="Separated">Separated</Option>
													<Option value="Divorced">Divorced</Option>
													<Option value="Single">Single</Option>
													<Option value="Unspecified">Unspecified</Option>
												</Select>
											)}
										</Form.Item>
									</Col>
								</Row>
							</Col>

							<Col span={12}>
								<Form.Item label="Education level">
									{getFieldDecorator("educationLevel", {
										...(patient && { initialValue: patient.educationLevel }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
									})(<Input />)}
								</Form.Item>
								<Row gutter={24}>
									<Col span={12}>
										<Form.Item label="Occupation">
											{getFieldDecorator("occupation", {
												...(patient && { initialValue: patient.occupation }),
												trigger: "onBlur",
												valuePropName: "defaultValue",
											})(<Input />)}
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Income">
											{getFieldDecorator("income", {
												...(patient && { initialValue: patient.income }),
												trigger: "onBlur",
												valuePropName: "defaultValue",
											})(<Input />)}
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col span={12}>
										<Form.Item label="Ethnicity">
											{getFieldDecorator("ethnicity", {
												...(patient && { initialValue: patient.ethnicity }),
												trigger: "onBlur",
												valuePropName: "defaultValue",
											})(<Input />)}
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Religion">
											{getFieldDecorator("religion", {
												...(patient && { initialValue: patient.religion }),
												trigger: "onBlur",
												valuePropName: "defaultValue",
											})(<Input />)}
										</Form.Item>
									</Col>
								</Row>
							</Col>
						</Row>
					</section>
				</Col>

				<Col span={12}>
					<section>
						<h2>Contact Details</h2>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label="Primary email">
									{getFieldDecorator("email", {
										...(patient && { initialValue: patient.email }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
									})(<Input type="email" />)}
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Secondary email">
									{getFieldDecorator("otherEmails", {
										...(patient && { initialValue: patient.otherEmails }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
									})(<Input type="email" />)}
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={8}>
								<Form.Item label="Mobile number">
									{getFieldDecorator("mobilePhone", {
										...(patient && {
											initialValue: patient.phones ? JSON.parse(patient.phones).mobile : null,
										}),
										trigger: "onBlur",
										valuePropName: "defaultValue",
									})(<Input type="tel" />)}
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Home phone number">
									{getFieldDecorator("homePhone", {
										...(patient && {
											initialValue: patient.phones ? JSON.parse(patient.phones).home : null,
										}),
										trigger: "onBlur",
										valuePropName: "defaultValue",
									})(<Input type="tel" />)}
								</Form.Item>
							</Col>

							<Col span={8}>
								<Form.Item label="Work phone number">
									{getFieldDecorator("workPhone", {
										...(patient && {
											initialValue: patient.phones ? JSON.parse(patient.phones).work : null,
										}),
										trigger: "onBlur",
										valuePropName: "defaultValue",
									})(<Input type="tel" />)}
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label="Preferred contact method">
									{getFieldDecorator("contactMethod", {
										initialValue: (patient && patient.contactMethod) || "Email",
									})(
										<Radio.Group size="large">
											<Radio value="Email">Email</Radio>
											<Radio value="Phone">Phone</Radio>
											<Radio value="SMS">SMS</Radio>
											<Radio value="Postal Mail">Postal Mail</Radio>
										</Radio.Group>
									)}
								</Form.Item>
							</Col>
						</Row>
					</section>

					<section>
						<h2>Medical Information</h2>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label="Additional Reference">
									{getFieldDecorator("hospitalRef", {
										...(patient && { initialValue: patient.hospitalRef }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
										rules: [
											{
												required: true,
												message: "Please enter a Reference Number",
											},
										],
									})(<Input />)}
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Locations">
									{getFieldDecorator("locations", {
										...(patient && {
											initialValue: patientLocations,
										}),
										trigger: "onBlur",
										valuePropName: "defaultValue",
										rules: [
											{
												required: true,
												message: "Please select at least one location",
											},
										],
									})(
										<Select
											id="locations"
											mode="multiple"
											allowClear
											showSearch
											optionFilterProp="children"
											value={patientLocations}
											// onChange={setPatientLocations}
											onChange={(e: any) => setPatientLocations(e)}
											filterOption={(input: any, option: any) =>
												option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											placeholder="Select locations"
											//disabled={!locations.length && locations.length < 0}
											//loading={!locations.length && locations.length < 0 ? true : false}
										>
											<Option disabled value="">
												Select locations
											</Option>
											{locations.map((location: Location) => {
												return (
													<Option key={location.id} value={location.name}>
														{location.name}
													</Option>
												);
											})}
										</Select>
									)}
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="NHS number">
									{getFieldDecorator("nhsNumber", {
										...(patient && { initialValue: patient.nhsNumber }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
									})(<Input />)}
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label="Registered consultant">
									{getFieldDecorator("primaryConsultantId", {
										...(patient && { initialValue: patient.primaryConsultantId }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
									})(
										<Select
											allowClear
											showSearch
											optionFilterProp="children"
											filterOption={(input: any, option: any) =>
												option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											placeholder="Select consultant"
											disabled={!users.length}
											loading={!users.length ? true : false}>
											<Option disabled value="">
												Select consultant
											</Option>
											{users
												.filter((user: User) => user.role === "consultant")
												.map((user: User) => {
													const fullName = `${user.title} ${user.firstName} ${user.lastName}`;
													return (
														<Option key={user.id} value={user.id}>
															{fullName}
														</Option>
													);
												})}
										</Select>
									)}
								</Form.Item>
							</Col>

							<Col span={12}>
								<Form.Item label="Research analyst">
									{getFieldDecorator("researchAnalystId", {
										...(patient && { initialValue: patient.researchAnalystId }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
									})(
										<Select
											allowClear
											showSearch
											optionFilterProp="children"
											filterOption={(input: any, option: any) =>
												option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											placeholder="Select analyst"
											disabled={!users.length}
											loading={!users.length ? true : false}>
											<Option disabled value="">
												Select analyst
											</Option>
											{users
												.filter((user: User) => user.role === "analyst")
												.map((user: User) => {
													const fullName = `${user.title} ${user.firstName} ${user.lastName}`;
													return (
														<Option key={user.id} value={user.id}>
															{fullName}
														</Option>
													);
												})}
										</Select>
									)}
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label="Allergies">
									{getFieldDecorator("allergies", {
										...(patient && { initialValue: patient.allergies }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
									})(
										<TextArea
											rows={2}
											placeholder="Enter any allergy information"
											autosize={{ minRows: 2, maxRows: 4 }}
										/>
									)}
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Medication">
									{getFieldDecorator("medication", {
										...(patient && { initialValue: patient.medication }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
									})(
										<TextArea
											rows={2}
											placeholder="Enter any medication information"
											autosize={{ minRows: 2, maxRows: 4 }}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label="GP details">
									{getFieldDecorator("gpDetails", {
										...(patient && { initialValue: patient.gpDetails }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
									})(
										<TextArea
											rows={2}
											placeholder="Enter any GP information"
											autosize={{ minRows: 2, maxRows: 4 }}
										/>
									)}
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Insurance details">
									{getFieldDecorator("insuranceDetails", {
										...(patient && { initialValue: patient.insuranceDetails }),
										trigger: "onBlur",
										valuePropName: "defaultValue",
									})(
										<TextArea
											rows={2}
											placeholder="Enter any insurance information"
											autosize={{ minRows: 2, maxRows: 4 }}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>
					</section>
				</Col>
			</Row>

			<Form.Item>
				<Button
					loading={loading}
					disabled={loading}
					style={{
						position: "fixed",
						bottom: 0,
						width: "100%",
						right: 0,
						left: 0,
						background: "#1b4690",
					}}
					size="large"
					type="primary"
					htmlType="submit"
					block>
					{patient ? "Update" : "Create"} patient
				</Button>
			</Form.Item>
		</Form>
	);
};

export default Form.create<Props>()(CreateEditPatientForm);

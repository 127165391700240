import React, { ReactNode, useEffect, useState } from "react";
import styles from "./PatientForm.module.css";
import logo from "../../../static/images/logo-white.svg";
import { Phone, Email } from "../../icons";
import { Clinic } from "../../../models";
import { get } from "../../../services";
import { Tooltip } from "antd";

interface Props {
	children: ReactNode;
}

const PatientForm = ({ children }: Props) => {
	const [clinic, setClinic] = useState<Clinic | null>();

	const getClinic = async (clinicId: string) => {
		try {
			const res = await get("/clinics/" + clinicId);
			if (res.status === 200) {
				setClinic(res.data);
			}
		} catch (err) {
			console.log(err);
		}
	};
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const clinicId = urlParams.get("clinicId");
		if (clinicId) {
			getClinic(clinicId);
		}
	}, []);

	return (
		<div className={styles.page}>
			<header className={styles.header}>
				<div className={styles["side-panel"]}>
					{clinic && clinic.logo && (
						<img className={styles.logo} src={logo} alt="Medical Outcomes " />
					)}
				</div>

				<div className={styles["side-panel"]}>
					<div className={styles["contact-details"]}>
						<div className={styles["contact-method"]}>
							<Phone fill="#fff" size={25} className={styles.icon} />
							{clinic && clinic.contactNumber && (
								<a className={styles["contact-method-phone"]}>{clinic.contactNumber}</a>
							)}
						</div>

						<div className={styles["contact-method"]}>
							{clinic && clinic.email && (
								<>
									<Email fill="#fff" size={25} className={styles.icon} />
									{clinic.email.length > 22 ? (
										<Tooltip title={clinic.email}>{`${clinic.email.substring(0, 22)}...`}</Tooltip>
									) : (
										<a>{clinic.email}</a>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</header>

			<main className={styles.content}>{children}</main>

			<footer className={styles.footer}>
				<p>
					<small>&copy; {new Date().getFullYear()} Medical Outcomes</small>
				</p>
			</footer>
		</div>
	);
};

export default PatientForm;

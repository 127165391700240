import React from "react";
import Props from "./Icon.types";
import defaultProps from "./defaultProps";

const Female = ({ size, fill, ...otherProps }: Props) => (
	<svg
		{...otherProps}
		height={size}
		width={size}
		fill={fill}
		viewBox="0 0 384 384"
		xmlns="http://www.w3.org/2000/svg">
		<path d="M272 136C272 61.008 210.992 0 136 0S0 61.008 0 136c0 69.566 52.535 127.016 120 134.977V304H88c-8.832 0-16 7.168-16 16s7.168 16 16 16h32v32c0 8.832 7.168 16 16 16s16-7.168 16-16v-32h32c8.832 0 16-7.168 16-16s-7.168-16-16-16h-32v-33.023c67.465-7.961 120-65.41 120-134.977zm-240 0C32 78.656 78.656 32 136 32s104 46.656 104 104-46.656 104-104 104S32 193.344 32 136zm0 0" />
	</svg>
);

Female.defaultProps = defaultProps;

export default Female;

import React, { useState, useEffect, Fragment, useContext } from "react";
import { destroy, get } from "../../services";
import Layout from "../../components/layouts/Main";
import { Location } from "../../models";
import { Button, Table, Tag, Icon, message, Drawer, Tooltip, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { stringToColour } from "../../lib/stringToColor";
import createEditLocationForm from "../../components/locations/createEditLocationForm";
import CreateEditLocationForm from "../../components/locations/createEditLocationForm";
import { StoreState } from "../../Store";

const Locations = () => {
	const [locations, setLocations] = useState<Location[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [showCreateEdit, setShowCreateEdit] = useState<boolean>(false);
	const [currentLocationId, setCurrentLocationId] = useState<string | null>(null);
	const [editing, setEditing] = useState<boolean>(false);

	const state = useContext(StoreState);
	const { user } = state;

	const getData = async () => {
		const res = await get("/Locations/?include=clinic");
		try {
			const sortedLocations = res.data.sort((a: any, b: any) => a.name.localeCompare(b.name));
			setLocations(sortedLocations);
		} catch (error) {
			message.error("Unexpected Server Error: Failed to load Location data.");
		}

		setLoading(false);
	};

	function onEdit(locationId: string) {
		setEditing(true);
		setCurrentLocationId(locationId);
		setShowCreateEdit(true);
	}

	function onNew() {
		setEditing(false);
		setShowCreateEdit(true);
	}

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		if (user && user.role === "super-admin") {
			getData();
		}
	}, [user]);

	async function deleteLocation(LocationId: string) {
		try {
			const res = await destroy("/locations/" + LocationId);
			if (res.status === 200) {
				setLocations((locations) => locations.filter((location) => location.id !== LocationId));
				message.success("Successfully deleted location");
			}
			if (res.statusCode === 409) {
				message.info(res.message);
			}
		} catch (err) {
			console.log("Error while deleting location");
		}
	}

	const columns = [
		{
			title: "Name",
			key: "clinic.name",
			sorter: (a: Location, b: Location) => a.name.localeCompare(b.name),
			render: (text: string, row: Location) => <Fragment>{row.name}</Fragment>,
		},
		{
			title: "Address",
			key: "clinic.logo",
			sorter: (a: Location, b: Location) => a.address.localeCompare(b.address),
			render: (text: string, row: Location) => <Fragment>{row.address}</Fragment>,
		},
		{
			title: "Phone Number",
			key: "clinic.email",
			sorter: (a: Location, b: Location) => a.phoneNumber.localeCompare(b.phoneNumber),
			render: (text: string, row: Location) => <Fragment>{row.phoneNumber}</Fragment>,
		},
		{
			title: "",
			align: "right" as "right",
			key: "id",
			render: (text: string, row: Location) => (
				<Fragment>
					<Button size="default" type="link" onClick={() => onEdit(row.id)}>
						Edit
						<Icon type="edit" />
					</Button>
					<Popconfirm
						title="Are you sure delete this Location?"
						onConfirm={() => deleteLocation(row.id)}
						okText="Yes"
						cancelText="No">
						<Button size="default" type="link" style={{ color: "#a20909" }}>
							Delete
							<Icon type="delete" />
						</Button>
					</Popconfirm>
				</Fragment>
			),
		},
	];

	return (
		<Layout title="Locations" pagetitle="Locations">
			<Button type="primary" size="default" icon="plus" onClick={onNew}>
				Add new Location
			</Button>

			<Table
				loading={loading}
				rowKey="id"
				style={{ marginTop: 25 }}
				dataSource={locations}
				columns={columns}
				pagination={{ pageSize: 100 }}
			/>

			<Drawer
				width={600}
				title={`${editing ? "Edit" : "Create new"} clinic`}
				placement="right"
				closable={true}
				destroyOnClose
				onClose={() => setShowCreateEdit(false)}
				visible={showCreateEdit}>
				<CreateEditLocationForm
					editing={editing}
					locationId={currentLocationId || null}
					closeDrawer={() => setShowCreateEdit(false)}
					getData={getData}
				/>
			</Drawer>
		</Layout>
	);
};

export default Locations;

import Color from "color";

export const stringToColour = function(str: string) {
	var hash = 0;
	for (var i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	var colour = "#";
	for (var index = 0; index < 3; index++) {
		var value = (hash >> (index * 8)) & 0xff;
		colour += ("00" + value.toString(16)).substr(-2);
	}
	return Color(colour)
		.darken(0.25) // Make sure color is dark enough to be readable with white text
		.string();
};

import React, { useEffect, useContext, useState } from "react";
import Cookies from "js-cookie";
import { withRouter, RouteComponentProps } from "react-router";
import css from "./UserProfile.module.css";
import { get, put } from "../../services";
import { StoreDispatch, StoreState } from "../../Store";
import { Avatar, Skeleton, Button, message, Select } from "antd";
import { stringToColour } from "../../lib/stringToColor";
import { Clinic, User } from "../../models";

const UserProfile = ({ history }: RouteComponentProps) => {
	const state = useContext(StoreState);
	const { user } = state;
	const dispatch = useContext(StoreDispatch);
	const { Option } = Select;

	const [clinics, setClinics] = useState<Clinic[]>([]);

	const getClinics = async () => {
		try {
			const response = await get("/clinics/");
			if (response) {
				const sortedClinics = response.data.sort((a: Clinic, b: Clinic) =>
					a.name.localeCompare(b.name)
				);
				setClinics(sortedClinics);
			}
		} catch (error) {
			message.error("Error while retrieving the clinics.");
		}
	};

	// Fetch User
	async function getData() {
		const response = await get("/users/me", "clinic");
		const data = response.data;
		if (data) {
			dispatch({ type: "SET_USER", payload: data });
			if (data.role === "super-admin") {
				getClinics();
			}
		} else {
			logout();
		}
	}

	const handleClinicSelect = async (clinicId: string) => {
		try {
			const clinic: Clinic | undefined = clinics.find((clinic) => clinic.id === clinicId);
			if (user && clinic) {
				const payload: User = { ...user, clinicId: clinicId, clinic: clinic };
				const response = await put(`/users/${user.id}`, payload);
				if (response.status === 200) {
					dispatch({ type: "SET_USER", payload: payload });
					message.success(`Successfully updated users clinic. now set to - ${clinic.name}`);
				}
			}
		} catch (err) {
			console.log(err);
		}
	};
	useEffect(() => {
		if (!user) {
			getData();
		}
	});
	useEffect(() => {
		if (user && user.role === "super-admin") {
			getClinics();
		}
	}, []);

	if (!user)
		return (
			<div className={css.placeholder}>
				<Skeleton active avatar title={{ width: 200 }} paragraph={{ width: 250, rows: 1 }} />
			</div>
		);

	const logout = () => {
		Cookies.remove("token");
		history.push("/login");
		dispatch({ type: "DESTROY_STORE" });
	};

	return (
		<div className={css.profile}>
			{user && user.role === "super-admin" && (
				<div
					style={{
						padding: "0.75rem",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						marginRight: "3rem",
					}}>
					<h3 style={{ marginRight: "1rem" }}>Select a Clinic </h3>
					<Select
						value={user && user.clinic.name}
						onChange={handleClinicSelect}
						style={{ width: 200 }}>
						<Option disabled value="">
							Select Clinic
						</Option>
						{clinics.map((clinic: Clinic) => (
							<Option key={clinic.id} value={clinic.id}>
								{clinic.name}
							</Option>
						))}
					</Select>
				</div>
			)}

			<Avatar size={48} icon="user" style={{ backgroundColor: stringToColour(user.email) }} />
			<div className={css.userInfo}>
				<p className={css.userName}>{user.firstName}</p>
				<Button className={css.logout} onClick={logout} type="link">
					Log Out
				</Button>
			</div>
		</div>
	);
};

export default withRouter(UserProfile);

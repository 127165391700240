import React, { useEffect, ReactElement } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface Props extends RouteComponentProps {
	children?: ReactElement;
}

const ScrollToTop = ({ children, location }: Props) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	return children || null;
};

export default withRouter(ScrollToTop);

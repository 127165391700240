import React, { useEffect, ReactNode } from "react";
import styles from "./Main.module.css";

import Sidebar from "../../Sidebar";
import Profile from "../../UserProfile";

interface Props {
	children: ReactNode;
	title?: string | ReactNode;
	pagetitle?: null | string;
}

const Main = ({ children, title, pagetitle }: Props) => {
	useEffect(() => {
		document.title = pagetitle ? pagetitle : "Medical Outcomes";
	}, [pagetitle]);

	return (
		<div className={styles.page}>
			<Sidebar />
			<main className={styles.content}>
				<div className={styles.topbar}>
					{title ? <h1>{title}</h1> : null}
					<Profile />
				</div>
				{children}
				<footer className={styles.footer}>
					<p>
						<small>&copy; {new Date().getFullYear()} Medical Outcomes</small>
					</p>
				</footer>
			</main>
		</div>
	);
};

export default Main;

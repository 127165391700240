export const countries = [
	{ id: "GB", value: "GB", label: "United Kingdom" },
	{ id: "AF", value: "AF", label: "Afghanistan" },
	{ id: "AX", value: "AX", label: "Åland Islands" },
	{ id: "AL", value: "AL", label: "Albania" },
	{ id: "DZ", value: "DZ", label: "Algeria" },
	{ id: "AS", value: "AS", label: "American Samoa" },
	{ id: "AD", value: "AD", label: "Andorra" },
	{ id: "AO", value: "AO", label: "Angola" },
	{ id: "AI", value: "AI", label: "Anguilla" },
	{ id: "AQ", value: "AQ", label: "Antarctica" },
	{ id: "AG", value: "AG", label: "Antigua and Barbuda" },
	{ id: "AR", value: "AR", label: "Argentina" },
	{ id: "AM", value: "AM", label: "Armenia" },
	{ id: "AW", value: "AW", label: "Aruba" },
	{ id: "AU", value: "AU", label: "Australia" },
	{ id: "AT", value: "AT", label: "Austria" },
	{ id: "AZ", value: "AZ", label: "Azerbaijan" },
	{ id: "BS", value: "BS", label: "Bahamas" },
	{ id: "BH", value: "BH", label: "Bahrain" },
	{ id: "BD", value: "BD", label: "Bangladesh" },
	{ id: "BB", value: "BB", label: "Barbados" },
	{ id: "BY", value: "BY", label: "Belarus" },
	{ id: "BE", value: "BE", label: "Belgium" },
	{ id: "BZ", value: "BZ", label: "Belize" },
	{ id: "BJ", value: "BJ", label: "Benin" },
	{ id: "BM", value: "BM", label: "Bermuda" },
	{ id: "BT", value: "BT", label: "Bhutan" },
	{ id: "BO", value: "BO", label: "Bolivia, Plurinational State of" },
	{ id: "BQ", value: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
	{ id: "BA", value: "BA", label: "Bosnia and Herzegovina" },
	{ id: "BW", value: "BW", label: "Botswana" },
	{ id: "BV", value: "BV", label: "Bouvet Island" },
	{ id: "BR", value: "BR", label: "Brazil" },
	{ id: "IO", value: "IO", label: "British Indian Ocean Territory" },
	{ id: "BN", value: "BN", label: "Brunei Darussalam" },
	{ id: "BG", value: "BG", label: "Bulgaria" },
	{ id: "BF", value: "BF", label: "Burkina Faso" },
	{ id: "BI", value: "BI", label: "Burundi" },
	{ id: "KH", value: "KH", label: "Cambodia" },
	{ id: "CM", value: "CM", label: "Cameroon" },
	{ id: "CA", value: "CA", label: "Canada" },
	{ id: "CV", value: "CV", label: "Cape Verde" },
	{ id: "KY", value: "KY", label: "Cayman Islands" },
	{ id: "CF", value: "CF", label: "Central African Republic" },
	{ id: "TD", value: "TD", label: "Chad" },
	{ id: "CL", value: "CL", label: "Chile" },
	{ id: "CN", value: "CN", label: "China" },
	{ id: "CX", value: "CX", label: "Christmas Island" },
	{ id: "CC", value: "CC", label: "Cocos (Keeling) Islands" },
	{ id: "CO", value: "CO", label: "Colombia" },
	{ id: "KM", value: "KM", label: "Comoros" },
	{ id: "CG", value: "CG", label: "Congo" },
	{ id: "CD", value: "CD", label: "Congo, the Democratic Republic of the" },
	{ id: "CK", value: "CK", label: "Cook Islands" },
	{ id: "CR", value: "CR", label: "Costa Rica" },
	{ id: "CI", value: "CI", label: "Côte d'Ivoire" },
	{ id: "HR", value: "HR", label: "Croatia" },
	{ id: "CU", value: "CU", label: "Cuba" },
	{ id: "CW", value: "CW", label: "Curaçao" },
	{ id: "CY", value: "CY", label: "Cyprus" },
	{ id: "CZ", value: "CZ", label: "Czech Republic" },
	{ id: "DK", value: "DK", label: "Denmark" },
	{ id: "DJ", value: "DJ", label: "Djibouti" },
	{ id: "DM", value: "DM", label: "Dominica" },
	{ id: "DO", value: "DO", label: "Dominican Republic" },
	{ id: "EC", value: "EC", label: "Ecuador" },
	{ id: "EG", value: "EG", label: "Egypt" },
	{ id: "SV", value: "SV", label: "El Salvador" },
	{ id: "GQ", value: "GQ", label: "Equatorial Guinea" },
	{ id: "ER", value: "ER", label: "Eritrea" },
	{ id: "EE", value: "EE", label: "Estonia" },
	{ id: "ET", value: "ET", label: "Ethiopia" },
	{ id: "FK", value: "FK", label: "Falkland Islands (Malvinas)" },
	{ id: "FO", value: "FO", label: "Faroe Islands" },
	{ id: "FJ", value: "FJ", label: "Fiji" },
	{ id: "FI", value: "FI", label: "Finland" },
	{ id: "FR", value: "FR", label: "France" },
	{ id: "GF", value: "GF", label: "French Guiana" },
	{ id: "PF", value: "PF", label: "French Polynesia" },
	{ id: "TF", value: "TF", label: "French Southern Territories" },
	{ id: "GA", value: "GA", label: "Gabon" },
	{ id: "GM", value: "GM", label: "Gambia" },
	{ id: "GE", value: "GE", label: "Georgia" },
	{ id: "DE", value: "DE", label: "Germany" },
	{ id: "GH", value: "GH", label: "Ghana" },
	{ id: "GI", value: "GI", label: "Gibraltar" },
	{ id: "GR", value: "GR", label: "Greece" },
	{ id: "GL", value: "GL", label: "Greenland" },
	{ id: "GD", value: "GD", label: "Grenada" },
	{ id: "GP", value: "GP", label: "Guadeloupe" },
	{ id: "GU", value: "GU", label: "Guam" },
	{ id: "GT", value: "GT", label: "Guatemala" },
	{ id: "GG", value: "GG", label: "Guernsey" },
	{ id: "GN", value: "GN", label: "Guinea" },
	{ id: "GW", value: "GW", label: "Guinea-Bissau" },
	{ id: "GY", value: "GY", label: "Guyana" },
	{ id: "HT", value: "HT", label: "Haiti" },
	{ id: "HM", value: "HM", label: "Heard Island and McDonald Islands" },
	{ id: "VA", value: "VA", label: "Holy See (Vatican City State)" },
	{ id: "HN", value: "HN", label: "Honduras" },
	{ id: "HK", value: "HK", label: "Hong Kong" },
	{ id: "HU", value: "HU", label: "Hungary" },
	{ id: "IS", value: "IS", label: "Iceland" },
	{ id: "IN", value: "IN", label: "India" },
	{ id: "ID", value: "ID", label: "Indonesia" },
	{ id: "IR", value: "IR", label: "Iran, Islamic Republic of" },
	{ id: "IQ", value: "IQ", label: "Iraq" },
	{ id: "IE", value: "IE", label: "Ireland" },
	{ id: "IM", value: "IM", label: "Isle of Man" },
	{ id: "IL", value: "IL", label: "Israel" },
	{ id: "IT", value: "IT", label: "Italy" },
	{ id: "JM", value: "JM", label: "Jamaica" },
	{ id: "JP", value: "JP", label: "Japan" },
	{ id: "JE", value: "JE", label: "Jersey" },
	{ id: "JO", value: "JO", label: "Jordan" },
	{ id: "KZ", value: "KZ", label: "Kazakhstan" },
	{ id: "KE", value: "KE", label: "Kenya" },
	{ id: "KI", value: "KI", label: "Kiribati" },
	{ id: "KP", value: "KP", label: "Korea, Democratic People's Republic of" },
	{ id: "KR", value: "KR", label: "Korea, Republic of" },
	{ id: "KW", value: "KW", label: "Kuwait" },
	{ id: "KG", value: "KG", label: "Kyrgyzstan" },
	{ id: "LA", value: "LA", label: "Lao People's Democratic Republic" },
	{ id: "LV", value: "LV", label: "Latvia" },
	{ id: "LB", value: "LB", label: "Lebanon" },
	{ id: "LS", value: "LS", label: "Lesotho" },
	{ id: "LR", value: "LR", label: "Liberia" },
	{ id: "LY", value: "LY", label: "Libya" },
	{ id: "LI", value: "LI", label: "Liechtenstein" },
	{ id: "LT", value: "LT", label: "Lithuania" },
	{ id: "LU", value: "LU", label: "Luxembourg" },
	{ id: "MO", value: "MO", label: "Macao" },
	{ id: "MK", value: "MK", label: "Macedonia, the Former Yugoslav Republic of" },
	{ id: "MG", value: "MG", label: "Madagascar" },
	{ id: "MW", value: "MW", label: "Malawi" },
	{ id: "MY", value: "MY", label: "Malaysia" },
	{ id: "MV", value: "MV", label: "Maldives" },
	{ id: "ML", value: "ML", label: "Mali" },
	{ id: "MT", value: "MT", label: "Malta" },
	{ id: "MH", value: "MH", label: "Marshall Islands" },
	{ id: "MQ", value: "MQ", label: "Martinique" },
	{ id: "MR", value: "MR", label: "Mauritania" },
	{ id: "MU", value: "MU", label: "Mauritius" },
	{ id: "YT", value: "YT", label: "Mayotte" },
	{ id: "MX", value: "MX", label: "Mexico" },
	{ id: "FM", value: "FM", label: "Micronesia, Federated States of" },
	{ id: "MD", value: "MD", label: "Moldova, Republic of" },
	{ id: "MC", value: "MC", label: "Monaco" },
	{ id: "MN", value: "MN", label: "Mongolia" },
	{ id: "ME", value: "ME", label: "Montenegro" },
	{ id: "MS", value: "MS", label: "Montserrat" },
	{ id: "MA", value: "MA", label: "Morocco" },
	{ id: "MZ", value: "MZ", label: "Mozambique" },
	{ id: "MM", value: "MM", label: "Myanmar" },
	{ id: "NA", value: "NA", label: "Namibia" },
	{ id: "NR", value: "NR", label: "Nauru" },
	{ id: "NP", value: "NP", label: "Nepal" },
	{ id: "NL", value: "NL", label: "Netherlands" },
	{ id: "NC", value: "NC", label: "New Caledonia" },
	{ id: "NZ", value: "NZ", label: "New Zealand" },
	{ id: "NI", value: "NI", label: "Nicaragua" },
	{ id: "NE", value: "NE", label: "Niger" },
	{ id: "NG", value: "NG", label: "Nigeria" },
	{ id: "NU", value: "NU", label: "Niue" },
	{ id: "NF", value: "NF", label: "Norfolk Island" },
	{ id: "MP", value: "MP", label: "Northern Mariana Islands" },
	{ id: "NO", value: "NO", label: "Norway" },
	{ id: "OM", value: "OM", label: "Oman" },
	{ id: "PK", value: "PK", label: "Pakistan" },
	{ id: "PW", value: "PW", label: "Palau" },
	{ id: "PS", value: "PS", label: "Palestine, State of" },
	{ id: "PA", value: "PA", label: "Panama" },
	{ id: "PG", value: "PG", label: "Papua New Guinea" },
	{ id: "PY", value: "PY", label: "Paraguay" },
	{ id: "PE", value: "PE", label: "Peru" },
	{ id: "PH", value: "PH", label: "Philippines" },
	{ id: "PN", value: "PN", label: "Pitcairn" },
	{ id: "PL", value: "PL", label: "Poland" },
	{ id: "PT", value: "PT", label: "Portugal" },
	{ id: "PR", value: "PR", label: "Puerto Rico" },
	{ id: "QA", value: "QA", label: "Qatar" },
	{ id: "RE", value: "RE", label: "Réunion" },
	{ id: "RO", value: "RO", label: "Romania" },
	{ id: "RU", value: "RU", label: "Russian Federation" },
	{ id: "RW", value: "RW", label: "Rwanda" },
	{ id: "BL", value: "BL", label: "Saint Barthélemy" },
	{ id: "SH", value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha" },
	{ id: "KN", value: "KN", label: "Saint Kitts and Nevis" },
	{ id: "LC", value: "LC", label: "Saint Lucia" },
	{ id: "MF", value: "MF", label: "Saint Martin (French part)" },
	{ id: "PM", value: "PM", label: "Saint Pierre and Miquelon" },
	{ id: "VC", value: "VC", label: "Saint Vincent and the Grenadines" },
	{ id: "WS", value: "WS", label: "Samoa" },
	{ id: "SM", value: "SM", label: "San Marino" },
	{ id: "ST", value: "ST", label: "Sao Tome and Principe" },
	{ id: "SA", value: "SA", label: "Saudi Arabia" },
	{ id: "SN", value: "SN", label: "Senegal" },
	{ id: "RS", value: "RS", label: "Serbia" },
	{ id: "SC", value: "SC", label: "Seychelles" },
	{ id: "SL", value: "SL", label: "Sierra Leone" },
	{ id: "SG", value: "SG", label: "Singapore" },
	{ id: "SX", value: "SX", label: "Sint Maarten (Dutch part)" },
	{ id: "SK", value: "SK", label: "Slovakia" },
	{ id: "SI", value: "SI", label: "Slovenia" },
	{ id: "SB", value: "SB", label: "Solomon Islands" },
	{ id: "SO", value: "SO", label: "Somalia" },
	{ id: "ZA", value: "ZA", label: "South Africa" },
	{ id: "GS", value: "GS", label: "South Georgia and the South Sandwich Islands" },
	{ id: "SS", value: "SS", label: "South Sudan" },
	{ id: "ES", value: "ES", label: "Spain" },
	{ id: "LK", value: "LK", label: "Sri Lanka" },
	{ id: "SD", value: "SD", label: "Sudan" },
	{ id: "SR", value: "SR", label: "Surilabel" },
	{ id: "SJ", value: "SJ", label: "Svalbard and Jan Mayen" },
	{ id: "SZ", value: "SZ", label: "Swaziland" },
	{ id: "SE", value: "SE", label: "Sweden" },
	{ id: "CH", value: "CH", label: "Switzerland" },
	{ id: "SY", value: "SY", label: "Syrian Arab Republic" },
	{ id: "TW", value: "TW", label: "Taiwan, Province of China" },
	{ id: "TJ", value: "TJ", label: "Tajikistan" },
	{ id: "TZ", value: "TZ", label: "Tanzania, United Republic of" },
	{ id: "TH", value: "TH", label: "Thailand" },
	{ id: "TL", value: "TL", label: "Timor-Leste" },
	{ id: "TG", value: "TG", label: "Togo" },
	{ id: "TK", value: "TK", label: "Tokelau" },
	{ id: "TO", value: "TO", label: "Tonga" },
	{ id: "TT", value: "TT", label: "Trinidad and Tobago" },
	{ id: "TN", value: "TN", label: "Tunisia" },
	{ id: "TR", value: "TR", label: "Turkey" },
	{ id: "TM", value: "TM", label: "Turkmenistan" },
	{ id: "TC", value: "TC", label: "Turks and Caicos Islands" },
	{ id: "TV", value: "TV", label: "Tuvalu" },
	{ id: "UG", value: "UG", label: "Uganda" },
	{ id: "UA", value: "UA", label: "Ukraine" },
	{ id: "AE", value: "AE", label: "United Arab Emirates" },
	{ id: "US", value: "US", label: "United States" },
	{ id: "UM", value: "UM", label: "United States Minor Outlying Islands" },
	{ id: "UY", value: "UY", label: "Uruguay" },
	{ id: "UZ", value: "UZ", label: "Uzbekistan" },
	{ id: "VU", value: "VU", label: "Vanuatu" },
	{ id: "VE", value: "VE", label: "Venezuela, Bolivarian Republic of" },
	{ id: "VN", value: "VN", label: "Viet Nam" },
	{ id: "VG", value: "VG", label: "Virgin Islands, British" },
	{ id: "VI", value: "VI", label: "Virgin Islands, U.S." },
	{ id: "WF", value: "WF", label: "Wallis and Futuna" },
	{ id: "EH", value: "EH", label: "Western Sahara" },
	{ id: "YE", value: "YE", label: "Yemen" },
	{ id: "ZM", value: "ZM", label: "Zambia" },
	{ id: "ZW", value: "ZW", label: "Zimbabwe" }
];

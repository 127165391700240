import dotenv from "dotenv";
import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
dotenv.config();

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL
});

instance.interceptors.request.use((config: AxiosRequestConfig) => {
	// Sets authorization header before request is fired from cookies if
	// the page is refreshed and headers are lost
	const token = Cookies.get(
		window.location.pathname === "/patient-form" ? "patientToken" : "token" // make sure admin and patient tokens are different
	);

	const tokenString = "Bearer " + token;
	if (token && config.headers.common.Authorization !== tokenString) {
		config.headers.common.Authorization = tokenString;
	}

	return config;
});

instance.interceptors.response.use(
	(response: AxiosResponse) => {
		// If response gives us a token then set all following
		// requests to include token in headers and set token as a cookie
		if (response.data.data.token) {
			const token = response.data.data.token;
			axios.defaults.headers.common.Authorization = "Bearer " + token;
			Cookies.set("token", token, { expires: 2, secure: process.env.NODE_ENV === "production" });
		}

		return response.data;
	},
	(error: AxiosError) => {
		const response = error.response;

		if (response) {
			const status = response.status;

			// Request returned 401/403 so the token must have expired
			if (window.location.pathname !== "/login" && window.location.pathname !== "/patient-form") {
				if (status === 401 || status === 403) {
					// Redirect to login and remove invalid token
					Cookies.remove("token");
					localStorage.clear();
					window.location.href = "/login?sessionTimeout=1";
				}
			}

			// Return HTTPException error object from server
			return Promise.reject(response.data);
		}

		// Server did not respond, show generic error
		return Promise.reject({
			statusCode: 500,
			message: "Unexpected server error. Please try again."
		});
	}
);

export default instance;

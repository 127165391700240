import React, { FormEvent, useContext, useEffect, useRef, useState } from "react"; // Import React and useState from 'react'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, Checkbox, Col, Form, Input, Row, message, Tooltip, Icon, Divider } from "antd";
import Layout from "../layouts/Main";
import { get, post, put } from "../../services";
import { Email } from "../../models/Email";
import { FormComponentProps } from "antd/lib/form";
import { StoreState } from "../../Store";
import { Redirect } from "react-router";
import { Clinic } from "../../models";
import css from "./Email.module.css";
import MailOutlined from "@ant-design/icons";

interface Props extends FormComponentProps {
	email: null | Email;
}

const CreateEditEmailForm = ({ form, email }: Props) => {
	const state = useContext(StoreState);
	const { user } = state;
	const [body, setEmailBody] = useState(email ? email.body : "");
	const [emailName, setEmailName] = useState(email ? email.emailName : "");
	const [fromAddress, setFromAddress] = useState(email ? email.fromAddress : "");
	const [useDefaultSender, setUseDefaultSender] = useState(Boolean);
	const [isDefault, setIsDefault] = useState(email ? email.isDefault : false);
	const [createdEmail, setCreatedEmail] = useState<Email | null>(null);
	const [clinic, setClinic] = useState<Clinic | null>(null);
	const quillRef = useRef<ReactQuill>(null);

	const { getFieldDecorator, setFieldsValue } = form;
	const { TextArea } = Input;

	const modules = {
		toolbar: [
			// specify Quill modules and options
			[{ list: "ordered" }, { list: "bullet" }],
			["blockquote"],
			["clean"],
		],
	};

	useEffect(() => {
		if (user && user.role === "super-admin") {
			setClinic(user.clinic);
		}
	}, [user]);

	const handleEmailBodyChange = (content: string) => {
		setEmailBody(content);
	};

	async function handleSave(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		if (user && user.clinicId) {
			form.validateFields(async (err) => {
				if (!err) {
					const editor = quillRef.current && quillRef.current.getEditor();
					const editorContent = editor && editor.getText().trim();
					if (!editorContent) {
						message.error("Email cannot be saved. Please enter email content before saving.");
						return;
					}
					const payload = {
						body,
						emailName,
						fromAddress,
						isDefault,
					};
					const res = email
						? await put("/mails/" + email.id, payload)
						: await post("/mails/", payload);

					if (res.data) {
						message.success(`Email successfully ${email ? "updated" : "created"}`);
						setCreatedEmail(res.data);
					} else {
						message.error("Unexpected Server Error: Failed to save email.");
					}
				} else {
					message.info("There are errors on the form. Please fix them before continuing.");
				}
			});
		} else {
			message.info("You have no yet set a clinic, please set a clinic on the user screen.");
		}
	}

	if (createdEmail) {
		return <Redirect to={"/emails/"} />;
	}

	return (
		<Form onSubmit={(event) => handleSave(event)} className={css.emailForm}>
			<Row gutter={12}>
				<Divider orientation="left" style={{ fontSize: "1.5rem", margin: "1rem 0 2rem" }}>
					Email Details
				</Divider>
				<Col span={10}>
					<h3>Email Name:</h3>
					<Form.Item>
						{getFieldDecorator("emailName", {
							...(email && { initialValue: email.emailName }),
							rules: [{ required: true, message: "Please enter the email name." }],
						})(<Input placeholder="Email Name" onChange={(e) => setEmailName(e.target.value)} />)}
					</Form.Item>
				</Col>
				<Col span={14}>
					<h3>Sender Email Address:</h3>
					<Row>
						<Col span={18}>
							<Form.Item>
								{getFieldDecorator("fromAddress", {
									...(email && { initialValue: email.fromAddress }),
									rules: [
										{ required: true, message: "Please enter the sender email." },
										{ type: "email", message: "Please enter a valid email address." },
									],
								})(
									<Input
										disabled={useDefaultSender}
										placeholder="Sender Email"
										onChange={(e) => setFromAddress(e.target.value)}
									/>
								)}
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item>
								<Tooltip title="Use clinic sender email">
									<Checkbox
										style={{ marginLeft: "0.5rem", lineHeight: "1.2rem" }}
										checked={useDefaultSender}
										onChange={(e) => {
											const checked = e.target.checked;
											setUseDefaultSender(checked);
											if (clinic && clinic.email && checked) {
												form.setFieldsValue({ fromAddress: clinic.email });
												setFromAddress(clinic.email);
											}
										}}>
										Use Clinic sender
									</Checkbox>
								</Tooltip>
							</Form.Item>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row style={{ marginBottom: "1rem" }}>
				<Divider orientation="left" style={{ fontSize: "1.5rem", margin: "1rem 0 2rem" }}>
					Email Header
				</Divider>
				<Col span={12}>
					<div>
						<h3>Dear [Patient Name],</h3>
						<h3>Firstly, thank you for choosing us for your [Treatment] procedure.</h3>
					</div>
				</Col>
			</Row>
			<Row style={{ margin: "1rem 0 5rem" }}>
				<Col span={24}>
					<Divider orientation="left" style={{ fontSize: "1.5rem", margin: "1rem 0 2rem" }}>
						Email Content
					</Divider>

					<Form.Item>
						<ReactQuill
							style={{ height: "30rem" }}
							value={body}
							onChange={handleEmailBodyChange}
							modules={modules}
							ref={quillRef}
							className="custom-editor"
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Divider orientation="left" style={{ fontSize: "1.5rem", margin: "1rem 0 2rem" }}>
						Email Footer
					</Divider>
					<h3>
						If you have any further questions regarding the questionnaires, please do not hesitate
						to contact our team on:
					</h3>
					<h3>Email: {fromAddress}</h3>
					<h3>Telephone: {clinic && clinic.contactNumber}</h3>
					<h3>With Thanks:</h3>
					<h3>{clinic && clinic.name}</h3>
				</Col>
			</Row>
			<Row>
				<Col span={16} offset={4}>
					<Button
						size="large"
						type="primary"
						block
						htmlType="submit"
						style={{ borderRadius: "1rem", fontSize: "large", marginTop: "5rem" }}>
						{email ? "Save" : "Create"}
					</Button>
				</Col>
			</Row>
		</Form>
	);
};

export default Form.create<Props>()(CreateEditEmailForm);

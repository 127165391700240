import React, { Fragment } from "react";

import css from "./Overview.module.css";

import { Email, Phone, Mobile, Male, Female } from "../../../components/icons";
import { Patient } from "../../../models";
import { Button, Card, Tag, Divider } from "antd";
import { Link } from "react-router-dom";
import { stringToColour } from "../../../lib/stringToColor";
import moment from "moment";

interface Props {
	patient: Patient;
}

const Overview = ({ patient }: Props) => {
	const {
		id,
		title,
		firstName,
		lastName,
		aka,
		dob,
		gender,
		hospitalRef,
		email,
		primaryAnalyst,
		primaryConsultant,
	} = patient;
	const phones = JSON.parse(patient.phones);

	return (
		<section className={css.overview}>
			<Card style={{ background: "#fafafa" }}>
				<h2 className={css.patientTitle}>
					{gender === "Male" ? (
						<Male size={35} fill="#1984f3" />
					) : gender === "Female" ? (
						<Female size={35} fill="#ff007f" />
					) : null}
					<span>{title}</span>&nbsp;
					<span>{firstName}</span>&nbsp;
					{aka ? <span>({aka})&nbsp;</span> : null}
					<span style={{ textDecoration: "underline" }}>{lastName} </span>
				</h2>

				<h3>
					Date of birth: <strong>{moment(dob).format("DD/MM/YYYY")}</strong>, (
					{moment().diff(dob, "years")} years old)
				</h3>
				<p>
					Additional Reference: <strong>{hospitalRef}</strong>
				</p>

				<p>
					{primaryConsultant && (
						<Fragment>
							<span>Consultant: </span>
							<Tag color={stringToColour(primaryConsultant.email)}>
								{primaryConsultant.title} {primaryConsultant.firstName} {primaryConsultant.lastName}
							</Tag>
						</Fragment>
					)}

					{primaryAnalyst && (
						<Fragment>
							<span>Analyst: </span>
							<Tag color={stringToColour(primaryAnalyst.email)}>
								{primaryAnalyst.title} {primaryAnalyst.firstName} {primaryAnalyst.lastName}
							</Tag>
						</Fragment>
					)}
				</p>

				<div className={css.contactDetails}>
					{email ? (
						<div className={css.contactDetail}>
							<Email className={css.contactDetailIcon} />
							{email}
						</div>
					) : null}

					{phones && phones.mobile ? (
						<div className={css.contactDetail}>
							<Mobile className={css.contactDetailIcon} />
							{phones.mobile}
						</div>
					) : null}

					{phones && phones.home ? (
						<div className={css.contactDetail}>
							<Phone className={css.contactDetailIcon} />
							{phones.home}
						</div>
					) : null}

					<p style={{ width: "100%" }}>
						Preferred contact: <strong>{patient.contactMethod}</strong>
					</p>
				</div>

				<Link to={"/patients/edit/" + id}>
					<Button type="primary" size="default" icon="user">
						View full details
					</Button>
				</Link>
			</Card>
		</section>
	);
};

export default Overview;

import React, { useState } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import { IconButton } from "@material-ui/core";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { Button } from "antd";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
	inputHeight: {
		height: 12,
	},
});

function SearchBar(props: any) {
	const { classes, getPatients } = props;
	const [value, setValue] = useState("");

	function handleKeyPress(event: any) {
		if (event.key === "Enter") {
			getPatients(value);
		}
	}

	return (
		<div style={{ float: "right", marginRight: 20 }}>
			<TextField
				className="form-control mr-sm-2"
				placeholder="Search by Last Name"
				type="text"
				variant="outlined"
				size="small"
				style={{ marginLeft: 25 }}
				onKeyUp={(e) => handleKeyPress(e)}
				onChange={(e) => {
					setValue(e.target.value);
					if (e.target.value === "") {
						getPatients();
					}
				}}
				value={value}
				InputProps={{
					classes: { input: classes.inputHeight },
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),

					endAdornment: value && (
						<IconButton
							size="small"
							aria-label="toggle password visibility"
							onClick={() => {
								setValue("");
								getPatients();
							}}>
							<CancelRoundedIcon />
						</IconButton>
					),
				}}
			/>
			<Button
				type="primary"
				size="default"
				style={{ marginLeft: 8, marginTop: 0 }}
				onClick={() => {
					getPatients(value);
				}}>
				Search
			</Button>
		</div>
	);
}

export default withStyles(styles)(SearchBar);

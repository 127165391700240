import React, { FormEvent, useContext, useEffect, useState } from "react";
import { FormComponentProps } from "antd/lib/form";
import {
	Form,
	Select,
	Skeleton,
	Input,
	Row,
	Col,
	Button,
	message,
	Upload,
	Icon,
	Popconfirm,
	Checkbox,
} from "antd";
import { Clinic, Location } from "../../models";
import { get, put, post, destroy } from "../../services";
import { UploadFile } from "antd/lib/upload/interface";
import { StoreState } from "../../Store";

interface Values {
	name: string;
	address: string;
	email: string;
	phoneNumber: string;
	addLocationToAllPatients: Boolean;
	addLocationToAllUsers: Boolean;
}

interface Props extends FormComponentProps {
	locationId: string | null;
	closeDrawer: () => void;
	getData: () => void;
	editing: boolean;
}

const CreateEditLocationForm = ({ form, locationId, closeDrawer, getData, editing }: Props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [location, setLocation] = useState<Location | null>(null);
	const state = useContext(StoreState);
	const { user } = state;
	const [clinics, setClinics] = useState<Clinic[] | null>([]);

	const { getFieldDecorator } = form;
	const { Option } = Select;

	async function getLocation() {
		try {
			const res = await get("/locations/" + locationId);
			setLocation(res.data);
		} catch (error) {
			console.log(error);
			message.error("Unexpected Server Error: Failed to load location data.");
		}
	}

	useEffect(() => {
		if (locationId) getLocation();
	}, [locationId]);

	function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		form.validateFieldsAndScroll(async (err, values: Values) => {
			if (!err) {
				setLoading(true);

				const res = editing
					? await put("/locations/" + locationId, values)
					: await post("/locations/", values);
				if (res.data) {
					await getData();
					closeDrawer();
					message.success(`Successfully ${editing ? "updated" : "created"} Location`);
				} else {
					if (res.statusCode === 409) {
						message.error("A Location with this name address already exists.");
					} else {
						message.error("Unexpected Server Error: Failed to save Location data.");
					}
				}

				setLoading(false);
			} else {
				setLoading(false);
				message.error("Invalid power bi link");
			}
		});
	}

	if ((editing && !locationId) || (editing && !location)) {
		return <Skeleton active />;
	}

	return (
		<Form autoComplete="off" layout="vertical" onSubmit={(event) => handleSubmit(event)}>
			<Row gutter={16}>
				<Col sm={24}>
					<Form.Item label="Location name">
						{getFieldDecorator("name", {
							...(editing && location && { initialValue: location.name }),
							rules: [
								{
									required: true,
									message: "Please enter a location name",
								},
							],
						})(<Input />)}
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col sm={12}>
					<Form.Item label="Number">
						{getFieldDecorator("phoneNumber", {
							...(editing && location && { initialValue: location.phoneNumber }),
						})(<Input />)}
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Address">
						{getFieldDecorator("address", {
							...(editing && location && { initialValue: location.address }),
						})(<Input />)}
					</Form.Item>
				</Col>
			</Row>
			{/* <Row>
				<Row style={{ marginBottom: "1rem" }}>
					<h3>Bulk Add:</h3>
				</Row>
				<Row>
					<Col sm={12}>
						<Form.Item>
							{getFieldDecorator("addLocationToAllUsers", {
								valuePropName: "checked",
								initialValue: false,
							})(<Checkbox>Add location to all users</Checkbox>)}
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item>
							{getFieldDecorator("addLocationToAllPatients", {
								valuePropName: "checked",
								initialValue: false,
							})(<Checkbox>Add location to all patients</Checkbox>)}
						</Form.Item>
					</Col>
				</Row>
			</Row> */}
			<Form.Item>
				<Button
					loading={loading}
					disabled={loading}
					size="large"
					type="primary"
					htmlType="submit"
					block>
					Save
				</Button>
			</Form.Item>
		</Form>
	);
};

export default Form.create<Props>()(CreateEditLocationForm);

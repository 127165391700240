import React, { useContext } from "react";
import Layout from "../../components/layouts/Main";
import { StoreState } from "../../Store";

const Home = () => {
	const state = useContext(StoreState);
	const { user } = state;
	return (
		<>
			<Layout title="Dashboard" pagetitle="Dashboard">
				{user && user.clinic && user.clinic.power_bi_link ? (
					<iframe
						width="100%"
						height="800"
						title="Daily returned patient scores/answers"
						frameBorder="1"
						allowFullScreen
						src={user.clinic.power_bi_link}
					/>
				) : (
					<h2>No Power Bi Link set up for the clinic</h2>
				)}
			</Layout>
		</>
	);
};

export default Home;

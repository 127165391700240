import React, { useEffect, ReactNode } from "react";
import styles from "./Unauthenticated.module.css";
import logo from "../../../static/images/logo.svg";

interface Props {
	children: ReactNode;
	pageTitle?: string;
}

const Unauthenticated = ({ children, pageTitle }: Props) => {
	useEffect(() => {
		const defaultTitle = "Medical Outcomes";
		document.title = pageTitle ? `${pageTitle} | ${defaultTitle}` : defaultTitle;
	}, [pageTitle]);

	return (
		<main className={styles.wrapper}>
			<section className={styles.content}>
				<div className={styles["logo-wrapper"]}>
					<img className={styles.logo} src={logo} alt="Medical Outcomes" />
				</div>
				<div className={styles["form-wrapper"]}>{children}</div>
			</section>
		</main>
	);
};

export default Unauthenticated;

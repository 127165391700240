import React, { FormEvent, useEffect, useState } from "react";
import { FormComponentProps } from "antd/lib/form";
import {
	Form,
	Select,
	Skeleton,
	Input,
	Row,
	Col,
	Button,
	message,
	Upload,
	Icon,
	Popconfirm,
} from "antd";
import { Clinic } from "../../models";
import { get, put, post, destroy } from "../../services";
import { UploadFile } from "antd/lib/upload/interface";

interface Values {
	name: string;
	logo: string;
	email: string;
	file: UploadFile;
	contactNumber: string;
	address: string;
	power_bi_link: string;
}

interface Props extends FormComponentProps {
	clinicId: string | null;
	closeDrawer: () => void;
	getData: () => void;
	editing: boolean;
}

const CreateEditClinicForm = ({ form, clinicId, closeDrawer, getData, editing }: Props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [clinic, setClinic] = useState<Clinic | null>(null);
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [isRemoved, setIsRemoved] = useState<boolean>(false);
	const normFile = (e: any) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};

	const { getFieldDecorator } = form;
	const { Option } = Select;

	async function getClinic() {
		try {
			const res = await get("/clinics/" + clinicId);
			setClinic(res.data);
		} catch (error) {
			console.log(error);
			message.error("Unexpected Server Error: Failed to load clinic data.");
		}
	}

	const isUrlValid = (url: string) => {
		try {
			new URL(url);
			message.info("New Power Bi link set, please log out and in again to see the changes.");
			return true;
		} catch (error) {
			return false;
		}
	};

	useEffect(() => {
		if (clinicId) getClinic();
	}, [clinicId]);

	const handleChange = (info: any) => {
		let fileList = Array.isArray(info.fileList) ? [...info.fileList] : [];

		fileList = fileList.slice(-1); // limit file list to one file
		fileList = fileList.map((file) => {
			if (file.response) {
				// Component will show file.url as link
				file.url = file.response.url;
			}
			return file;
		});
		fileList = fileList.filter((file) => {
			if (file.type === "image/jpeg" || file.type === "image/png") {
				return true;
			} else {
				message.error(`${file.name} is not a valid image file.`);
				return false;
			}
		});
		fileList = fileList.map((file) => {
			if (file.size > 2 * 1024 * 1024) {
				message.error(`${file.name} is larger than 2MB.`);
				return null;
			}
			return file;
		});
		fileList = fileList.filter((file) => !!file);
		setFileList(fileList);
	};
	const handleRemoveLogo = async () => {
		try {
			if (clinic) {
				const response = await destroy(`/clinics/${clinic.id}/logo`);
				if (response.status === 200) {
					setIsRemoved(true);
					message.success(response.message);
					await getData();
					closeDrawer();
				} else {
					message.error("Failed to remove logo");
				}
			}
		} catch (error) {
			console.error("Failed to remove logo:", error);
			message.error("Failed to remove logo");
		}
	};

	function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		form.validateFieldsAndScroll(async (err, values: Values) => {
			if (!err) {
				setLoading(true);
				const { power_bi_link } = values;
				if (!power_bi_link || isUrlValid(power_bi_link)) {
					const formData = new FormData();
					formData.append("name", values.name);
					formData.append("email", values.email);
					if (values.address) {
						formData.append("address", values.address);
					}
					formData.append("contactNumber", values.contactNumber);
					if (values.power_bi_link) {
						formData.append("power_bi_link", values.power_bi_link);
					}
					if (fileList && fileList.length > 0) {
						if (fileList[0].originFileObj) {
							formData.append("logo", fileList[0].originFileObj);
						}
					}
					const config = {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					};
					const res = editing
						? await put("/clinics/" + clinicId, formData, config)
						: await post("/clinics/", formData, config);
					if (res.data) {
						await getData();
						closeDrawer();
						message.success(`Successfully ${editing ? "updated" : "created"} clinic`);
					} else {
						if (res.statusCode === 409) {
							message.error("A clinic with this name address already exists.");
						} else {
							message.error("Unexpected Server Error: Failed to save clinic data.");
						}
					}

					setLoading(false);
				} else {
					setLoading(false);
					message.error("Invalid power bi link");
				}
			}
		});
	}

	if ((editing && !clinicId) || (editing && !clinic)) {
		return <Skeleton active />;
	}

	return (
		<Form autoComplete="off" layout="vertical" onSubmit={(event) => handleSubmit(event)}>
			<Row gutter={16}>
				<Col sm={12}>
					<Form.Item label="Clinic name">
						{getFieldDecorator("name", {
							...(editing && clinic && { initialValue: clinic.name }),
							rules: [
								{
									required: true,
									message: "Please enter a clinic name",
								},
							],
						})(<Input />)}
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item label="Email">
						{getFieldDecorator("email", {
							...(editing && clinic && { initialValue: clinic.email }),
							rules: [
								{ required: true, message: "Please enter an email." },
								{ type: "email", message: "Please enter a valid email address." },
							],
						})(<Input />)}
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col sm={24}>
					<Form.Item label="Logo">
						{getFieldDecorator("logo", {
							getValueFromEvent: normFile,
							...(editing && clinic && { initialValue: clinic.logo }),
						})(
							<div>
								{editing && clinic && clinic.logo && !isRemoved ? (
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
										}}>
										<img
											src={clinic.logo}
											alt="clinic logo"
											style={{
												borderRadius: "1rem",
												maxWidth: "100%",
												height: "12rem",
												width: "16rem",
												marginBottom: "1rem",
											}}
										/>
										<div>
											<Popconfirm
												title="Are you sure delete this Logo?"
												onConfirm={handleRemoveLogo}
												okText="Yes"
												cancelText="No">
												<Button type="primary" size="default">
													Delete Logo
												</Button>
											</Popconfirm>
										</div>
									</div>
								) : (
									<Upload.Dragger
										beforeUpload={() => false}
										accept=".jpg,.png,.jpeg"
										onChange={handleChange}>
										<p className="ant-upload-drag-icon">
											<Icon type="inbox" />
										</p>
										<p className="ant-upload-text">Click or drag file to this area to upload</p>
										<p className="ant-upload-hint">Only supports JPG, PNG, JPEG files.</p>
									</Upload.Dragger>
								)}
							</div>
						)}
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col sm={12}>
					<Form.Item label="Contact Number">
						{getFieldDecorator("contactNumber", {
							...(editing && clinic && { initialValue: clinic.contactNumber }),
							rules: [
								{ required: true, message: "Please enter a contact number." },
								{ pattern: /^\d+$/, message: "Please enter a valid phone number with no letters." },
							],
						})(<Input />)}
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item label="Address">
						{getFieldDecorator("address", {
							...(editing && clinic && { initialValue: clinic.address }),
							// rules: [
							// 	{
							// 		required: true,
							// 		message: "Please enter a last name",
							// 	},
							// ],
						})(<Input />)}
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<Form.Item label="Power BI Link">
						{getFieldDecorator("power_bi_link", {
							...(editing && clinic && { initialValue: clinic.power_bi_link }),
							// rules: [
							// 	{
							// 		required: true,
							// 		message: "Please enter a clinic name",
							// 	},
							// ],
						})(<Input />)}
					</Form.Item>
				</Col>
			</Row>
			<Form.Item>
				<Button
					loading={loading}
					disabled={loading}
					size="large"
					type="primary"
					htmlType="submit"
					block>
					Save
				</Button>
			</Form.Item>
		</Form>
	);
};

export default Form.create<Props>()(CreateEditClinicForm);

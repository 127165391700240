import React, { useState, useEffect, useContext, FormEvent } from "react";
import { Button, Col, Collapse, Form, Icon, Row, Select, Tag, Tooltip, message } from "antd";
import { Email } from "../../models/Email";
import { EmailToReview, ReviewType } from "../../models";
import { Box } from "@material-ui/core";
import css from "./ReviewItem.module.css";
import { put } from "../../services";
import { StoreState } from "../../Store";

interface Props {
	emails: Email[];
	globalReviewType: ReviewType;
	activePanel: string;
	activeEmail: string;
	defaultEmail: string;
	handlePanelChange: (key: any) => void;
}

const ReviewItem = ({
	emails,
	globalReviewType,
	activePanel,
	activeEmail,
	defaultEmail,
	handlePanelChange,
}: Props) => {
	const { Option } = Select;
	const { Panel } = Collapse;

	const handleEmailSelect = async (emailId: string, reviewTypeId: string) => {
		try {
			await put("/mails/add-update-email-to-review", {
				emailId: emailId,
				reviewTypeId: reviewTypeId,
			});
		} catch (error) {
			console.error(error);
			message.error("Error while updating email template");
		}
	};

	return (
		<Collapse activeKey={activePanel} onChange={handlePanelChange} bordered={false}>
			<Panel
				style={{
					borderRadius: "0.3rem",
					fontSize: "medium",
					minHeight: "4rem",
				}}
				header={globalReviewType.name}
				key={globalReviewType.id ? globalReviewType.id : Math.random().toString(36).substring(7)}>
				<Box className={css.customEmailBox}>
					<Row>
						<Col span={12}>
							<h4>Select an Email Template</h4>
							<Form.Item style={{ alignSelf: "center" }}>
								<Select
									defaultValue={activeEmail ? activeEmail : defaultEmail}
									allowClear
									style={{ width: "12rem" }}
									onChange={(value: any) =>
										handleEmailSelect(
											value ? value.toString() : "",
											globalReviewType.id ? globalReviewType.id : ""
										)
									}>
									{emails &&
										emails.map((email: Email) => (
											<Option key={email.id} value={email.id}>
												{email.isDefault ? `${email.emailName} - Default` : email.emailName}
											</Option>
										))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<hr className={css.horizontalLine} />
					</Row>
				</Box>
			</Panel>
		</Collapse>
	);
};
export default ReviewItem;
